/* ==========================================================================
   Start of custom Webflow CSS
   ========================================================================== */

simple-search {
  color: black;
}
.event-card:hover {
  transform: scale(0.99);
  transition: transform 0.4s ease-in-out;
}
.event-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.event-dialog {
  background-color: #fff;
  width: 80%;
  max-width: 800px;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.event-dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.event-dialog-header h2 {
  margin: 0;
}

.event-dialog-body img {
  width: 100%;
  max-width: 300px;
  margin: 1rem 0;
}

.event-dialog-body p {
  margin: 0.5rem 0;
}
.padding-bottom-25 {
  padding-bottom: 25px;
}
.event-card-bg {
  border: 0.4px solid #9d9d9d45;
  background-color: #161616b0;
}
@font-face {
  font-family: Monument-Extended;
  src: url(./assets/fonts/MonumentExtended-Ultrabold.otf);
}
@font-face {
  font-family: Monument-Extended-Normal;
  src: url(./assets/fonts/MonumentExtended-Regular.otf);
}

.landing-title-sub {
  font-size: 30px;
  font-family: Monument-Extended-Normal;
  font-weight: 100;
  color: #e7e9ea;
}
.landing-title-main {
  text-align: center;
  font-size: 30px;
  font-family: Monument-Extended;
}

/* Gradient Scrollbar */
body::-webkit-scrollbar {
  width: 2px;
}

svg {
  display: inline !important;
}
body::-webkit-scrollbar-track {
  background: linear-gradient(
    0deg,
    rgba(255, 0, 0, 1) 0%,
    rgba(7, 0, 211, 1) 100%
  );
}

body::-webkit-scrollbar-thumb {
  background: transparent; /* opacity: 0; should do the thing either */
  box-shadow: 0px 0px 0px 100000vh black;
}
/* ================================================================================ */
.w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}
.about-vitap {
  font-size: 50px;
}

.about-boxing {
  display: flex;
  align-items: top;
}
.about-box {
  display: flex;
  align-items: top;
}

.about-img {
  display: flex;
  align-items: right;
  width: 200px;
  height: 200px;
}

.about-des {
  margin: 10px 100px auto 100px;
  float: left;
  line-height: 2;
}

@media screen and (max-width: 600px) {
  .landing-title-main {
    text-align: center;
    font-size: 20px;
    font-family: Monument-Extended;
  }
  .landing-title-sub {
    font-size: 19px;
    font-family: Monument-Extended-Normal;
    font-weight: 100;
    color: #e7e9ea;
  }
  .about-des {
    margin: auto auto 50px auto;
  }
}

.about-head {
  text-align: left;
  float: left;
}
@media screen and (max-width: 600px) {
  .about-head {
    margin: auto 50px auto auto;
  }
}

.vit-team {
  text-align: center;
  padding: 30px;
}
.vit-team2 {
  text-align: center;
  padding: 30px;
}
.vit-team3 {
  text-align: center;
  padding: 30px;
}

@media screen and (max-width: 600px) {
  .vit-team {
    margin: auto 110px auto auto;
  }
}
@media screen and (max-width: 600px) {
  .vit-team2 {
    margin: auto 0px auto auto;
  }
}
@media screen and (max-width: 600px) {
  .vit-team3 {
    margin: auto 0px auto auto;
  }
}

.mention-card-chance {
  align-items: center;
}

.car {
  position: absolute;
  right: 100px;
  top: 20px;
  width: 250px;
  height: 250px;
  padding: 200px auto 200px auto;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.body {
  height: 100px;
  width: 100px;
}
.cardis {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  padding: 20px;
  grid-gap: 40px;
}
.cardi {
  background-color: #1c1b29;
  border-radius: 20px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.18);
  width: 200px;
  height: 320px;
}
.cardi :hover {
  transition: 0.5s;
  opacity: 0.5;
}

.imgg {
  width: 100%;
  display: block;
  border-radius: 20px 20px 0 0;
}

.event-padding {
  padding: 5px;
}
/* .container:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 20px 20px 0 0;
  opacity: 0.7;
} */
.cardi1 > .container:after {
  background-image: linear-gradient(135deg);
}
.details {
  padding: 20px 10px;
}
.details > h3 {
  color: #fff;
  font-weight: 600;
  font-size: 45px;
  margin: 10px 0 15px 0;
}
.details > p {
  color: #a0a0a0;
  font-size: 30px;
  line-height: 30px;
  font-weight: 400;
}

.column {
  float: left;
  width: 25%;
  padding: 0 10px;
}

/* Remove extra left and right margins, due to padding in columns */
.row {
  margin: 0 -5px;
}

/* Clear floats after the columns */
.row:after {
  content: '';
  display: table;
  clear: both;
}

/* Style the counter cards */
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* this adds the "card" effect */
  padding: 16px;
  text-align: center;
  background-color: #f1f1f1;
  border: 4px solid;
}

/* Responsive columns - one column layout (vertical) on small screens */
@media screen and (max-width: 600px) {
  .column {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }
}
.ViswanthanSir {
  width: 200px;
}
.KotaReddySir {
  width: 200px;
}
.RegisterSir {
  width: 200px;
}
.SudhakarSir {
  width: 200px;
}
.AnupamaMam {
  width: 200px;
}
.SamuelSir {
  width: 200px;
}
.MonaliMam {
  width: 200px;
}
.FaizanSir {
  width: 200px;
}
.AswathyMam {
  width: 200px;
}
.RavindraSir {
  width: 200px;
}
.SureshSir {
  width: 200px;
}

.Schedule1 {
  position: absolute;
  left: 45px;
}
.Vitopia21 {
  width: 100%;
}
@media only screen and (max-width: 600px) {
  .Vitopia21 {
    width: 180px;
  }
}

.w-form-formradioinput--inputType-custom {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-color: #ccc;
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  border-right-color: #ccc;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  width: 12px;
  height: 12px;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
}

.w-form-formradioinput--inputType-custom.w--redirected-focus {
  box-shadow: 0px 0px 3px 1px #3898ec;
}

.w-form-formradioinput--inputType-custom.w--redirected-checked {
  border-top-width: 4px;
  border-bottom-width: 4px;
  border-left-width: 4px;
  border-right-width: 4px;
  border-top-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  border-right-color: #3898ec;
}

body {
  background-color: #000;
  font-family: Poppins, sans-serif;
  color: #fff;
  font-size: 16px;
  line-height: 1em;
}

h1 {
  position: relative;
  z-index: 5;
  margin-top: 0px;
  margin-bottom: 10px;
  font-family: Poppins, sans-serif;
  color: #fff;
  font-size: 90px;
  line-height: 1em;
  font-weight: 800;
}

h2 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 66px;
  line-height: 1em;
  font-weight: 800;
  text-transform: capitalize;
}

h3 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 50px;
  line-height: 1em;
  font-weight: 700;
}

h4 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 26px;
  line-height: 1em;
  font-weight: 800;
}

h5 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 1.2em;
  font-weight: 700;
}

h6 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 20px;
  line-height: 1.2em;
  font-weight: 700;
}

p {
  margin-bottom: 0px;
  font-size: 16px;
  line-height: 1.5em;
  font-weight: 400;
}

a {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #fff;
  font-size: 16px;
  line-height: 1.5em;
  text-decoration: none;
}

a:hover {
  color: #1d35a1;
}

ul {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-row-gap: 15px;
}

li {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;

  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-position: 0% 7px;
  background-size: auto;
  background-repeat: no-repeat;
  font-size: 16px;
  line-height: 1.4em;
}

label {
  display: block;
  margin-bottom: 10px;
  font-weight: 700;
}

blockquote {
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 30px 20px 10px;
  border-left: 1px none #000;
  background-image: url('https://assets.website-files.com/636e23ecf118df495e7a2aca/636e23edf118df107f7a2b19_bxs_quote-left.png');
  background-position: 50% 0%;
  background-size: auto;
  background-repeat: no-repeat;
  font-family: Lato, sans-serif;
  font-size: 36px;
  line-height: 1.2;
  font-weight: 700;
}

figure {
  margin-bottom: 10px;
}

.section {
  position: relative;
  overflow: hidden;
  padding-top: 40px;
  padding-bottom: 40px;
}

.section.licensing-fonts {
  padding-top: 0px;
  padding-bottom: 0px;
}

.section.blog-sidebar {
  overflow: visible;
}

.section.works-details {
  padding-top: 120px;
}

.section.blog-post {
  padding-top: 120px;
}

.section.top-spacing {
  padding-top: 80px;
}

.section.landing-page {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 140px;
  padding-bottom: 80px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.section.color-section {
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: #1d35a1;
}

.section.padding-bottom-big {
  padding-bottom: 80px;
}

.section.padding-big {
  padding-top: 80px;
  padding-bottom: 80px;
}

.section.padding-top-big {
  padding-top: 80px;
}

.base-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.base-container.align-left {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.base-container.stretch {
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.grid-system-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.grid-system {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 80%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-top: 1px solid #7c7c7c;
}

.grid-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 19%;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 20px 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-right: 1px solid #7c7c7c;
  border-left: 1px none #a7a7a7;
  background-color: #1d35a1;
  color: #fff;
}

.grid-title.first-grid {
  width: 24%;
  margin-top: 0px;
  border-left-style: solid;
  border-left-color: #7c7c7c;
  background-color: #1d35a1;
}

.grid-description {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 19%;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 20px 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-right: 1px solid #a7a7a7;
  border-bottom: 1px solid #a7a7a7;
}

.grid-description.first-description {
  width: 24%;
  border-right: 1px solid #a7a7a7;
  border-bottom: 1px solid #a7a7a7;
  border-left: 1px solid #a7a7a7;
}

.grid-description.last-description {
  border-right-style: solid;
  border-right-width: 1px;
}

.spacing-system-column {
  padding-right: 20px;
}

.spacing-system-image {
  max-width: 80%;
}

.spacing-wrapper-mobile {
  position: relative;
  width: 70%;
  margin-top: 30px;
}

.typography-wrapper {
  margin-top: 20px;
}

.spacing-columns {
  margin-top: 40px;
}

.bottom-style-spacing-desktop {
  position: absolute;
  left: auto;
  top: auto;
  right: 0%;
  bottom: 10%;
  font-weight: 700;
}

.top-style-spacing-desktop {
  position: absolute;
  top: 8%;
  right: 0px;
  bottom: auto;
  font-weight: 700;
}

.spacing-wrapper {
  position: relative;
  width: 100%;
  margin-top: 30px;
}

.middle-style-spacing-desktop {
  position: absolute;
  left: auto;
  top: 33%;
  right: 0%;
  bottom: auto;
  font-weight: 700;
}

.spasing-system-image-mobile {
  max-width: 70%;
}

.primary-style-guide {
  width: 75px;
  height: 75px;
  background-color: #1d35a1;
  color: #1d35a1;
}

.color-wrapper {
  margin-right: 30px;
}

.colors-wrapper {
  margin-right: 60px;
}

.text-dark-style-guide {
  width: 75px;
  height: 75px;
  background-color: #000;
  box-shadow: 0 0 20px 10px hsla(0, 0%, 100%, 0.1);
}

.colors-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 30px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.color-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 30px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.primary-light-style-guide {
  width: 75px;
  height: 75px;
  background-color: #fff;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
}

.headings-typography-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-right: 40px;
  margin-bottom: 30px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.headings-typography-wrapper.last-child {
  margin-right: 0px;
  margin-bottom: 0px;
}

.headers-wrapper {
  width: 100%;
  margin-top: 10px;
}

.h1-tablet {
  font-size: 80px;
}

.h2-tablet {
  font-size: 66px;
}

.h1-mobile {
  font-size: 48px;
}

.h2-mobile {
  font-size: 42px;
}

.h3-mobile {
  font-size: 36px;
}

.h3-tablet {
  font-size: 50px;
}

.style-guide-body-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 47%;
  margin-right: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.style-guide-body-wrapper.last-child {
  margin-right: 0px;
}

.primary-button.full-width-mobile {
  display: block;
}

.style-guide-div {
  width: 100%;
  margin-top: 50px;
}

.top-style-spacing-mobile {
  position: absolute;
  top: 5%;
  right: 0px;
  font-weight: 700;
}

.middlr-style-spacing-mobile {
  position: absolute;
  top: 29%;
  right: 0px;
  font-weight: 700;
}

.bottom-style-spacing-mobile {
  position: absolute;
  right: 0px;
  bottom: 7%;
  font-weight: 700;
}

.grid-header {
  width: 100%;
  margin-top: 40px;
}

.link-wrapper {
  width: 50%;
  margin-top: 20px;
}

.navbar {
  position: fixed;
  width: 100%;
  background-color: transparent;
}

.nav-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1200px;
  padding: 20px 15px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.nav-menu {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: right;
}

.nav-link {
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-transition-property: all;
  transition-property: all;
  font-family: Poppins, sans-serif;
  color: #fff;
  font-size: 16px;
  font-weight: 0;
}

.nav-link:hover {
  color: #1d35a1;
}

.nav-link.footer-link {
  padding-right: 25px;
  padding-left: 25px;
  color: #fff;
}

.nav-link.footer-link:hover {
  color: #1d35a1;
}

.nav-link.footer-link-first {
  padding-left: 0px;
  color: #fff;
}

.nav-link.footer-link-first:hover {
  color: #1d35a1;
}

.nav-dropdown-toggle {
  margin-right: 0px;
  margin-left: 0px;
  padding: 10px 25px 5px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.nav-dropdown-toggle:hover {
  color: #1d35a1;
}

.nav-dropdown-icon {
  display: none;
  font-size: 16px;
}

.nav-dropdown-list {
  width: 200px;
  padding: 15px 20px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.18);
  border-radius: 10px;
  background-color: #fff;
}

.nav-dropdown-list.w--open {
  padding-top: 20px;
  padding-bottom: 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.04);
}

.nav-dropdown-list.pages {
  width: 240px;
}

.nav-dropdown-link-wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  padding-right: 0px;
  padding-left: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.nav-dropdown-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-left: -20px;
  padding: 8px 8px 8px 0px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
}

.nav-dropdown-link:hover {
  margin-left: 0px;
  color: #000000;
}

.nav-dropdown-link.w--current {
  color: #000000;
}

.nav-item-title {
  margin-top: 0px;
  margin-bottom: 8px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #d4d4d4;
  font-size: 16px;
  line-height: 1.5em;
  font-weight: 500;
}

.nav-item-title:hover {
  color: #ffffff;
}

.style-guide-banner-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 180px;
  padding-bottom: 140px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #1d35a1;
}

.licensing-title-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 40px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px none rgba(94, 94, 94, 0.5);
}

.licensing-images-wrapper {
  width: 100%;
  padding-top: 40px;
  border-top: 1px solid rgba(94, 94, 94, 0.5);
}

.licensing-grid {
  width: 100%;
  padding-bottom: 60px;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-bottom: 1px none rgba(94, 94, 94, 0.5);
}

.licensing-image {
  width: 100%;
  height: 320px;
  -o-object-fit: cover;
  object-fit: cover;
}

.licensing-image.eye {
  -o-object-position: 30% 0%;
  object-position: 30% 0%;
}

.licensing-title {
  width: 100%;
  padding-bottom: 30px;
}

.licensing-image-link {
  width: 100%;
  height: 100%;
}

.licensing-image-link:hover {
  opacity: 1;
}

.license-link {
  margin-right: 50px;
  color: #000;
  font-weight: 700;
  letter-spacing: normal;
}

.license-link.last-child {
  margin-right: 0px;
}

.banner-title {
  color: #f9f9fa;
  text-align: center;
}

.licensing-heading {
  margin-bottom: 20px;
}

.licensing-icon-link-wrapper {
  margin-right: 25px;
}

.icon-style-guide {
  font-family: 'Fa brands 400', sans-serif;
  color: #1d35a1;
  font-size: 24px;
}

.icon-style-guide.dribble {
  font-family: 'Fa solid 900', sans-serif;
}

.licensing-fonts-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.licensing-icon-link {
  margin-bottom: 20px;
}

.licensing-paragraph {
  width: 50%;
  margin-right: 40px;
}

.licensing-font-title {
  font-weight: 400;
  text-transform: uppercase;
}

.licensing-icons-wrapper {
  margin-top: 30px;
  margin-bottom: 20px;
}

.utility-page-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.utility-page-wrap.password {
  position: relative;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 0.7)),
    to(rgba(0, 0, 0, 0.7))
  );
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.7)
  );
}

.utility-page-content {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 280px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.utility-page-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.password-image {
  display: block;
  width: 140px;
  margin-right: auto;
  margin-left: auto;
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

.password-title-wrapper {
  margin-bottom: 40px;
}

._404-paragraph {
  margin-top: 20px;
  margin-bottom: 40px;
  text-align: center;
}

._404-logo-wrapper {
  position: absolute;
  left: 0%;
  top: 35px;
  right: 0%;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.text-center-404 {
  max-width: 500px;
  font-size: 60px;
  text-align: center;
}

._404-content-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 700px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.coming-soon-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 690px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.changelog-heading {
  margin-bottom: 20px;
}

.coming-soon-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.coming-soon-input {
  height: auto;
  max-width: 300px;
  min-width: 250px;
  border-style: none none solid;
  border-width: 1px;
  border-color: #e9e9e9;
  background-color: transparent;
  color: #fff;
}

.coming-soon-input:focus {
  border-style: none none solid;
  border-width: 1px;
  border-color: #afafaf;
}

.coming-soon-input::-webkit-input-placeholder {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 16px;
  font-weight: 300;
}

.coming-soon-input:-ms-input-placeholder {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 16px;
  font-weight: 300;
}

.coming-soon-input::-ms-input-placeholder {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 16px;
  font-weight: 300;
}

.coming-soon-input::placeholder {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 16px;
  font-weight: 300;
}

.coming-soon-input.coming-soon {
  margin-bottom: 0px;
}

.coming-soon-paragraph {
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
}

.coming-soon-heading {
  margin-top: 40px;
  font-size: 70px;
  text-align: center;
}

.blog-post-title {
  -webkit-transition: color 300ms ease;
  transition: color 300ms ease;
}

.blog-post-title:hover {
  color: #1d35a1;
}

.nav-menu-wrapper {

  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.blog-sidebar-item {
  display: inline-block;
  overflow: hidden;
  margin-bottom: 30px;
  border-radius: 10px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
}

.blog-sidebar-post-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: -16px;
  padding-bottom: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.blog-sidebar-post-category {
  display: inline-block;
  margin-top: -28px;
  margin-bottom: 10px;
  margin-left: 10px;
  padding: 5px 8px;
  border-radius: 10px;
  background-color: #1d35a1;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #fff;
}

.blog-sidebar-post-category:hover {
  background-color: #fff;
  color: #1d35a1;
}

.blog-sidebar-post-category.top-banner-blog-page {
  margin-top: 0px;
}

.blog-sidebar-left {
  -webkit-column-count: 2;
  column-count: 2;
  -webkit-column-gap: 30px;
  column-gap: 30px;
}

.blog-sidebar-right {
  position: -webkit-sticky;
  position: sticky;
  top: 100px;
  height: 540px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.blog-sidebar-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  grid-column-gap: 40px;
}

.sidebar-recent-posts-wrapper {
  margin-top: 20px;
}

.sidebar-recent-post-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
}

.sidebar-recent-post-image {
  width: 80px;
  height: 80px;
  margin-right: 15px;
  border-radius: 8px;
  -o-object-fit: cover;
  object-fit: cover;
}

.sidebar-recent-post {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.blog-recent-post-title {
  margin-top: 0px;
  font-size: 16px;
}

.div-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.blog-recent-post-date {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #969696;
  font-size: 14px;
}

.sidebar-recent-posts {
  margin-top: 30px;
  margin-bottom: 30px;
  padding-right: 20px;
  padding-left: 0px;
  border-radius: 10px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
}

.sidebar-categories {
  padding: 10px 20px 10px 0px;
  border-radius: 10px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
}

.sidebar-categories-wrapper {
  margin-top: 20px;
}

.blog-sidebar-post-image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.blog-sidebar-post-image-link {
  width: 100%;
  height: 100%;
}

.sidebar-category {
  display: inline-block;
  padding: 5px 8px;
  border-style: solid;
  border-width: 1px;
  border-color: #1d35a1;
  border-radius: 6px;
  background-color: #1d35a1;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #fff;
}

.sidebar-category:hover {
  background-color: transparent;
  color: #1d35a1;
}

.blog-template-wrapper {
  width: 100%;
  max-width: 920px;
  margin-right: auto;
  margin-left: auto;
}

.blog-template-image {
  width: 100%;
  max-height: 680px;
  margin-bottom: 20px;
  -o-object-fit: cover;
  object-fit: cover;
}

.blog-template-date {
  margin-top: 30px;
  margin-bottom: 40px;
  font-weight: 700;
}

.blog-template-pagination {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 80px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 60px;
  grid-row-gap: 60px;
}

.prev-post-arrow {
  margin-top: 0px;
  margin-right: 25px;
  margin-bottom: 0px;
  font-family: 'Fa solid 900', sans-serif;
  color: #afafaf;
}

.next-post-arrow {
  margin-bottom: 0px;
  margin-left: 25px;
  font-family: 'Fa solid 900', sans-serif;
  color: #afafaf;
}

.blog-template-prev-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.blog-template-next-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.blog-template-pagination-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.blog-template-next {
  text-align: right;
}

.footer {
  margin-top: 30px;
  padding-top: 60px;
  padding-bottom: 20px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-top: 1px solid hsla(0, 0%, 100%, 0.1);
  background-color: #000;
  text-align: center;
}

.footer-rights-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 20px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid hsla(0, 0%, 100%, 0.1);
}

.footer-copyright {
  text-align: left;
}

.section-title-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 30px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  text-align: center;
}

.section-title-wrapper.landing {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.style-guide-buttons-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 30px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 40px;
  grid-row-gap: 30px;
}

.style-guide-button-wrapper {
  width: 47%;
}

.style-guide-button-wrapper.last-child {
  display: none;
  margin-right: 0px;
}

.paragraph-large {
  font-size: 18px;
  line-height: 1.5em;
}

.play-button {
  position: absolute;
  z-index: 5;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: hsla(0, 0%, 100%, 0.4);
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-30.centered {
  max-width: 880px;
  margin-top: 20px;
  text-align: center;
}

.link-with-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.link-with-icon:hover {
  opacity: 0.7;
  color: #fff;
}

.paragraph-no-margin {
  margin-top: 0px;
  margin-bottom: 0px;
}

.footer-link {
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #afafaf;
}

.footer-link:hover {
  color: #fff;
}

.footer-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 60px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  text-align: left;
}

.footer-brand-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.without-top-spacing {
  padding-top: 0px;
}

.search-result-item {
  margin-bottom: 30px;
}

.search-link {
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  font-size: 18px;
}

.search-link:hover {
  opacity: 0.7;
}

.search-input {
  height: auto;
  margin-top: 20px;
  padding: 16px 22px;
  font-family: Lato, sans-serif;
  font-size: 16px;
}

.search-input:focus {
  border-style: solid;
  border-width: 1px;
  border-color: #1d35a1;
}

.search-result-wrapper {
  width: 100%;
  margin-top: 40px;
}

.search-section {
  margin-top: 20px;
  margin-bottom: 60px;
}

.list-item-circle {
  position: absolute;
  left: 0px;
  top: 7px;
  right: auto;
  bottom: auto;
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #1d35a1;
}

.headings-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.button-wrapper {
  margin-top: 20px;
}

.service-list-item {
  position: relative;
}

.style-guide-content-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 30px;
  grid-row-gap: 10px;
}

.slide-3 {
  max-height: 580px;
}

.z-3-tab-link {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 33.33%;
  margin-bottom: -12px;
  padding-top: 20px;
  padding-bottom: 4px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #fff;
  font-size: 19px;
  line-height: 32px;
}

.about-3-tab-link.w--current {
  padding-top: 20px;
  padding-bottom: 4px;
  background-color: transparent;
  color: #fff;
  font-size: 24px;
}

.portfolio-grid-collection-item {
  position: relative;
  overflow: hidden;
  width: 100%;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.08);
}

.template-empty-state {
  padding: 30px;
  background-color: transparent;
  font-family: Lato, sans-serif;
  color: #1d35a1;
  font-size: 16px;
}

.portfolio-masonry-image-wrapper {
  position: relative;
}

.project-template-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.project-template-content-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 40px;
}

.project-template-info-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-row-gap: 10px;
  border-radius: 10px;
  background-color: hsla(0, 0%, 100%, 0.15);
}

.paragraph-gray {
  color: #fff;
  font-size: 22px;
  line-height: 1em;
  font-weight: 800;
}

.project-template-social-icons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 3px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  font-size: 26px;
}

.project-template-social-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  font-family: 'Fa brands 400', sans-serif;
  font-size: 18px;
  line-height: 15px;
}

.project-template-social-link:hover {
  opacity: 0.6;
  color: #fff;
}

.project-template-social-link.last-child {
  margin-right: 0px;
}

.project-template-social-link.last-child:hover {
  background-color: transparent;
}

.project-template-banner-image {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.play-button-icon {
  position: relative;
  z-index: 7;
  margin-left: 4px;
  font-family: 'Fa solid 900', sans-serif;
  font-size: 20px;
}

.play-button-hover {
  position: absolute;
  z-index: 10;
  width: 94px;
  height: 94px;
  border-style: solid;
  border-width: 3px;
  border-color: hsla(0, 0%, 100%, 0);
  border-radius: 50%;
  -webkit-transform: scale(0.85);
  -ms-transform: scale(0.85);
  transform: scale(0.85);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.play-button-hover:hover {
  border-color: hsla(0, 0%, 100%, 0.4);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.sidebar-search {
  padding: 0px 20px 10px 0px;
  border-radius: 10px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
}

.sidebar-search-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.sidebar-search-input {
  height: auto;
  margin-bottom: 0px;
  margin-left: 0px;
  padding: 10px 22px 10px 0px;
  border-style: none none solid;
  border-width: 1px;
  border-color: hsla(0, 0%, 68.7%, 0.5) hsla(0, 0%, 68.7%, 0.5) #fff;
  background-color: transparent;
}

.sidebar-search-input:active {
  color: #fff;
}

.sidebar-search-input:focus {
  border-color: #1d35a1;
  color: #fff;
}

.sidebar-search-input:focus-visible {
  color: #fff;
}

.sidebar-search-input[data-wf-focus-visible] {
  color: #fff;
}

.sidebar-search-input::-webkit-input-placeholder {
  color: #afafaf;
  font-size: 16px;
}

.sidebar-search-input:-ms-input-placeholder {
  color: #afafaf;
  font-size: 16px;
}

.sidebar-search-input::-ms-input-placeholder {
  color: #afafaf;
  font-size: 16px;
}

.sidebar-search-input::placeholder {
  color: #afafaf;
  font-size: 16px;
}

.blog-sidebar-right-wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 36%;
  height: 770px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.blog-sidebar-left-wrapper {
  width: 64%;
}

.secondary-button {
  padding: 10px 30px;
  border-radius: 50px;
  background-color: transparent;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #fff;
  font-size: 16px;
  line-height: 1.5em;
  letter-spacing: 0.5px;
}

.secondary-button:hover {
  background-color: hsla(0, 0%, 100%, 0.1);
}

.testimonials {
  padding-top: 80px;
  padding-bottom: 80px;
}

.blog-section-title {
  margin-bottom: 60px;
  padding-right: 15px;
  padding-left: 15px;
  text-align: center;
}

.map-wrapper {
  position: relative;
}

.map-wrapper.home-page {
  width: 100%;
  margin-bottom: 60px;
}

.contacts-wrapper-grid {
  padding-right: 30px;
  padding-left: 30px;
  grid-column-gap: 30px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.section-title-center {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 780px;
  margin-right: auto;
  margin-bottom: 60px;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.courses-collection {
  width: 100%;
}

.home-4-testimonials-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 10px;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
}

.testimonials-wrapper-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 10px;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
}

.top-banner-button-wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 38px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.about-img {
  position: absolute;
  -o-object-fit: cover;
  object-fit: cover;
}

.about-img._1 {
  left: 12%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 2;
  width: 350px;
}

.about-img._5 {
  left: 24%;
  top: 60%;
  right: 0%;
  bottom: auto;
  z-index: 4;
  width: 350px;
}

.about-img._3 {
  left: auto;
  top: 19%;
  right: 4%;
  bottom: 0%;
  z-index: 1;
  width: 300px;
}

.about-img._4 {
  left: 50%;
  top: 47%;
  right: 30%;
  bottom: 0%;
  z-index: 5;
  width: 220px;
}

.about-img._2 {
  left: 2%;
  top: 31%;
  right: auto;
  bottom: 0%;
  z-index: 3;
  width: 300px;
}

.subtitle {
  display: inline-block;
  margin-right: 0px;
  margin-bottom: 30px;
  padding-bottom: 4px;
  border-bottom: 2px solid hsla(0, 0%, 50.2%, 0.15);
}

.our-services-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 33.33%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-row-gap: 20px;
}

.central-alignment-heaading {
  margin-bottom: 75px;
  text-align: center;
}

.heading-wrapper {
  margin-top: 40px;
  margin-bottom: 30px;
}

.content-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 190px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.home-1-latest-works-collection {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.home-projects-overlay {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 11;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.projects-name {
  margin-top: 0px;
  margin-bottom: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  text-transform: uppercase;
}

.home-projects-wrapper {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 1020px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
}

.content {
  margin-right: 15px;
  margin-bottom: 30px;
  margin-left: 15px;
  border-radius: 5px;
}

.video-lightbox {
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 80px;
  height: 80px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.video-lightbox.home-2 {
  right: 30px;
  bottom: 30px;
}

.industries-block-wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 35vw;
  padding-right: 20px;
  padding-bottom: 30px;
  padding-left: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  border-radius: 12px;
  background-color: transparent;
}

.industries-block-wrapper:hover {
  box-shadow: 0 10px 20px 0 rgba(20, 23, 51, 0.15);
}

.sidebar-categories-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.blog-details-link-title {
  color: #fff;
}

.home-9-team-item {
  position: relative;
  width: 31%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.about-4-team-slide {
  width: 23%;
  margin-right: 30px;
}

.about-6-projects-item {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 370px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  -o-object-fit: cover;
  object-fit: cover;
}

.section-with-blue-bg {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #1d35a1;
}

.nav-dropdown-link-line {
  display: inline-block;
  width: 16px;
  height: 2px;
  margin-right: 15px;
  background-color: #1d35a1;
}

.search {
  width: 100%;
}

.subtitle-2 {
  display: inline-block;
  margin-right: 0px;
  margin-bottom: 30px;
  padding-bottom: 4px;
  border-bottom: 2px solid hsla(0, 0%, 50.2%, 0.15);
  color: #fc8e44;
}

.project-title-wrapper {
  position: absolute;
  left: 0%;
  top: auto;
  right: auto;
  bottom: 20px;
  z-index: 12;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.dropdown-nav-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: -20px;
  padding: 5px 0px;
  -webkit-transition: color 200ms ease;
  transition: color 200ms ease;
  font-family: Poppins, sans-serif;
  color: #999;
}

.dropdown-nav-link:hover {
  color: #004ae2;
}

.dropdown-nav-link.w--current {
  color: #004ae2;
}

.gallery-image-link {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.home-19-gallery-img-link {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.menu-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.rich-text-style h2 {
  margin-bottom: 15px;
}

.rich-text-style h3 {
  margin-top: 25px;
  margin-bottom: 15px;
}

.rich-text-style h4 {
  margin-top: 25px;
  margin-bottom: 10px;
}

.rich-text-style h5 {
  margin-top: 20px;
}

.rich-text-style h6 {
  margin-top: 20px;
}

.home-banner-left-arrow {
  left: 2%;
  top: 0%;
  right: auto;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.home-23-team-item {
  width: 33%;
}

.center-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.brand-tablet {
  display: none;
}

.link-arrow-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-left: 10px;
  background-image: url('https://assets.website-files.com/636e23ecf118df495e7a2aca/636e3d0bf2c48f6b206445e3_Group%204.svg');
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.navbar-button-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.home-tab-underline {
  width: 20px;
  height: 3px;
  background-color: #fff;
}

.footer-wrapper-top {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.overflow-hidden-container {
  overflow: hidden;
  width: 100%;
}

.logo-loop-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 2399px;
  grid-column-gap: 50px;
  opacity: 0.4;
}

.logo-loop {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  grid-column-gap: 50px;
}

.loop-section {
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px;
}

.top-banner-section {
  overflow: hidden;
  height: 100vh;
}

.top-banner-section.about-us {
  height: auto;
  padding-top: 140px;
  padding-bottom: 60px;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.2);
}

.top-banner-section.about-us-2 {
  height: auto;
  padding-top: 110px;
  padding-bottom: 50px;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
}

.top-banner-section.blog-page {
  height: 60vh;
}

.top-banner-section.blog-category-page {
  height: auto;
  max-height: 500px;
  padding-top: 70px;
}

.background-video {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: 100vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#000),
    to(transparent)
  );
  background-image: linear-gradient(0deg, #000, transparent);
}

.background-video.home-3 {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-row-gap: 30px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#000),
    to(rgba(0, 0, 0, 0.3))
  );
  background-image: linear-gradient(0deg, #000, rgba(0, 0, 0, 0.3));
}

.background-video.blog-page {
  height: 60vh;
  padding-top: 30px;
}

.home-title {
  max-width: 770px;
  font-size: 100px;
  text-align: center;
}

.home-title.heading-top-banner-title-smaller {
  max-width: none;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #fff;
  opacity: 0.1;
}

.extra-bold-text {
  color: #000;
  font-size: 80px;
  line-height: 0.7em;
  font-weight: 700;
  text-transform: uppercase;
  text-shadow: -3px -1px 0 #fff, 3px -1px 0 #fff, -3px 1px 0 #fff,
    3px 1px 0 #fff, 2px -2px 0 #fff, 2px 2px 0 #fff, -2px 2px 0 #fff,
    -2px -2px 0 #fff;
}

.text-lines-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-row-gap: 40px;
}

.text-and-image-lines-wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 30px;
  border-radius: 10px;
}

.full-width-video {
  display: block;
  max-height: 80vh;
}

.full-width-video.centered {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 0.4)),
    to(rgba(0, 0, 0, 0.4))
  );
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0.4)
  );
}

.full-width-video.work-3 {
  max-height: 400px;
}

.arrow-icon {
  position: absolute;
  right: 30px;
  bottom: 30px;
  width: 30px;
  height: 30px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
}

.arrow-icon:hover {
  right: 25px;
  bottom: 25px;
}

.three-columns-grid {
  width: 100%;
  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: start;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.awards-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-row-gap: 10px;
}

.our-clients-names-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-row-gap: 50px;
}

.our-clients-names-wrapper.left-align {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  grid-row-gap: 30px;
}

.our-clients-names-wrapper.left-align-about-3 {
  max-width: 700px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  grid-row-gap: 30px;
}

.about-us-video-wrapper {
  overflow: hidden;
  margin-top: 30px;
  margin-right: 20px;
  margin-left: 20px;
  border-radius: 10px;
}

.image-wrapped-down {
  position: relative;
  overflow: hidden;
  max-height: 120px;
  margin-left: auto;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-radius: 10px;
}

.image-wrapped-down.last-item {
  max-width: 540px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.image-wrapped-down.first-item {
  width: 100%;
  max-width: 585px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.image-wrapped-down.mid-item {
  width: 100%;
  max-width: 300px;
  margin-left: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.image-wrapped-down.mid-item-copy {
  width: 100%;
  max-width: 300px;
  margin-left: auto;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.background-video-colors {
  overflow: visible;
  margin-top: -75px;
}

.background-video-about-us.about-us-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 0.3)),
    to(#000)
  );
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.3), #000);
  text-align: center;
}

.background-video-about-us.about-us-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-height: 450px;
  max-width: 570px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 0.4)),
    to(rgba(0, 0, 0, 0.4))
  );
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0.4)
  );
  text-align: center;
}

.background-video-about-us.blog-page {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-height: 400px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 0.3)),
    to(#000)
  );
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.3), #000);
  text-align: center;
}

.background-video-about-us.coming-soon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.two-columns-grid {
  width: 100%;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  -ms-grid-columns: 1fr 0.5fr;
  grid-template-columns: 1fr 0.5fr;
}

.latest-works-video-item {
  z-index: 1;
  width: 100%;
  height: 320px;
  border-radius: 10px;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 0.4)),
    to(rgba(0, 0, 0, 0.4))
  );
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0.4)
  );
  -o-object-fit: cover;
  object-fit: cover;
}

.latest-works-video-item.home-2-large {
  width: 48%;
  height: 59%;
}

.latest-works-video-item.home-2-smaller {
  width: 48%;
  height: 37%;
}

.video-title {
  position: absolute;
  left: 30px;
  top: auto;
  right: auto;
  bottom: 47px;
}

.home-video-player {
  position: relative;
}

.home-video-player.home-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  height: 100%;
  max-height: 600px;
  max-width: 570px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.video-player-image {
  -o-object-fit: cover;
  object-fit: cover;
}

.video-player-image.home-3 {
  height: 600px;
  border-radius: 10px;
}

.video-player-image.works-2 {
  min-height: 350px;
  border-radius: 10px;
}

.video-info-content-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  grid-column-gap: 125px;
}

.video-info-content-wrapper.works-2 {
  height: 100.0000062642993%;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  grid-column-gap: 100px;
}

.video-info-content-wrapper.works-2-top {
  height: 100.0000062642993%;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  grid-column-gap: 100px;
}

.video-info-block-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  grid-row-gap: 30px;
}

.max-width-580 {
  max-width: 580px;
  text-align: center;
}

.about-us-page-autoplay-video {
  overflow: hidden;
  max-height: 244px;
  border-radius: 10px;
}

.paragraph-and-button-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 460px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.heading-top-banner-title-smaller {
  margin-bottom: 0px;
  font-size: 70px;
  line-height: 1em;
}

.our-clients-link-block-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.our-clients-link-image {
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.our-clients-link-image:hover {
  opacity: 0.8;
}

.background-video-visual {
  overflow: visible;
  width: auto;
  margin-top: -115px;
}

.about-us-3-video-wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  margin-top: 20px;
  margin-right: 20px;
  margin-left: 20px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
}

.absolute-top-banner-title {
  position: absolute;
  width: 100%;
}

.about-us-background-video-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  min-height: 500px;
  padding-right: 85px;
  padding-left: 50px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  grid-row-gap: 30px;
  border-radius: 10px;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 0.24)),
    to(rgba(0, 0, 0, 0.8))
  );
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.24),
    rgba(0, 0, 0, 0.8)
  );
}

.max-width-600 {
  max-width: 600px;
}

.clients-background-video {
  max-height: 360px;
  max-width: 400px;
  border-radius: 10px;
}

.extra-bold-text-h3 {
  color: #000;
  font-size: 60px;
  line-height: 1em;
  font-weight: 800;
  text-shadow: -3px -1px 0 #fff, 3px -1px 0 #fff, -3px 1px 0 #fff,
    3px 1px 0 #fff, 2px -2px 0 #fff, 2px 2px 0 #fff, -2px 2px 0 #fff,
    -2px -2px 0 #fff;
}

.our-clients-content-wrapper-about-us-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  grid-column-gap: 70px;
}

.our-client-image-about-us-3-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 400px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.about-us-3-awards-content-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-row-gap: 30px;
}

.max-width-70 {
  width: 70%;
}

.contact-us-section {
  padding-top: 140px;
  padding-bottom: 100px;
}

.contact-us-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  grid-column-gap: 100px;
}

.have-an-idea-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 480px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-row-gap: 20px;
}

.contact-form-wrapper {
  width: 100%;
}

.contact-us-title {
  font-size: 78px;
}

.contact-us-info {
  width: 100%;
  max-width: 480px;
}

.contact-us-links-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 50px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-row-gap: 20px;
}

.contact-us-links-wrapper.mobile {
  display: none;
}

.contact-us-links-wrapper.desctop {
  margin-top: 40px;
}

.contact-us-form-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  margin-top: 20px;
  margin-bottom: 0px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.radio-buttons-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 45px;
  grid-column-gap: 40px;
}

.radio-button-field {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  padding: 10px 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.radio-button {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  width: 100%;
  height: 100%;
  margin-top: 0px;
  margin-left: 0px;
  border-style: none;
  border-radius: 60px;
  font-weight: 700;
}

.radio-button:hover {
  border-style: none;
}

.radio-button:active {
  border-style: none;
}

.radio-button.w--redirected-checked {
  border-style: solid;
  border-width: 1px;
  border-color: #fff;
}

.radio-button.w--redirected-focus {
  border-style: solid;
  border-width: 1px;
  border-color: #fff;
  box-shadow: none;
}

.radio-button.w--redirected-focus-visible {
  border-style: solid;
  border-width: 1px;
  border-color: #fff;
}

.radio-button-lable-contact-form {
  font-weight: 700;
  white-space: nowrap;
}

.contact-us-form-field {
  height: 64px;
  margin-bottom: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 0px;
  border-style: none none solid;
  border-width: 1px;
  border-color: #000 #000 hsla(0, 0%, 100%, 0.3);
  background-color: transparent;
  color: #fff;
  font-size: 16px;
  line-height: 1.5em;
}

.contact-us-form-field:focus {
  border-bottom-color: hsla(0, 0%, 100%, 0.5);
}

.contact-us-form-field:focus-visible {
  border-bottom-color: hsla(0, 0%, 100%, 0.5);
}

.contact-us-form-field[data-wf-focus-visible] {
  border-bottom-color: hsla(0, 0%, 100%, 0.5);
}

.contact-us-form-field::-webkit-input-placeholder {
  color: #fff;
}

.contact-us-form-field:-ms-input-placeholder {
  color: #fff;
}

.contact-us-form-field::-ms-input-placeholder {
  color: #fff;
}

.contact-us-form-field::placeholder {
  color: #fff;
}

.contact-us-form-field.first-item {
  height: 54px;
}

.contact-us-form-field.text-field {
  height: 100px;
  padding-top: 16px;
}

.contact-us-fields-wrapper {
  margin-bottom: 50px;
}

.contact-us-footer {
  padding-bottom: 50px;
}

.background-video-animation {
  overflow: visible;
}

.category-ablsolute-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.category-ablsolute-link.right-align {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  text-align: right;
}

.absolte {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 2;
}

.nav-link-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 25px;
  padding-left: 25px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.location-text-block {
  line-height: 1.5em;
}

.error-message {
  background-color: transparent;
}

.success-message {
  width: 100%;
  background-color: transparent;
}

.process-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-row-gap: 50px;
}

.e-1-background-videos {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1;
  display: -ms-grid;
  display: grid;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  grid-auto-columns: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.e-1-background-videos.home-1-copy {
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
}

.home-1-latest-works-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  grid-auto-columns: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.home-1-latest-works-list.home-2 {
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.home-1-latest-works-item {
  position: relative;
  display: inline-block;
  overflow: hidden;
  width: 48%;
  max-height: 320px;
  border-radius: 10px;
  -webkit-transition: opacity 300ms ease, -webkit-transform 300ms ease;
  transition: opacity 300ms ease, -webkit-transform 300ms ease;
  transition: opacity 300ms ease, transform 300ms ease;
  transition: opacity 300ms ease, transform 300ms ease,
    -webkit-transform 300ms ease;
}

.home-1-latest-works-item:first-child {
  width: 100%;
}

.home-1-latest-works-item:last-child {
  width: 100%;
}

.home-1-latest-works-item.works-1:first-child {
  width: 48%;
}

.home-1-latest-works-item.works-1:last-child {
  width: 48%;
}

.projects-1-latest-works-wrap {
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 30px;
  -webkit-transition: color 300ms ease;
  transition: color 300ms ease;
}

.home-3-latest-projects-wrapper {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 1400px;
  margin-top: 25px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
}

.home-3-latest-works-item {
  position: relative;
  z-index: 10;
  display: inline-block;
  overflow: hidden;
  width: 100%;
  max-height: 320px;
  border-radius: 10px;
  -webkit-transition: opacity 300ms ease, -webkit-transform 300ms ease;
  transition: opacity 300ms ease, -webkit-transform 300ms ease;
  transition: opacity 300ms ease, transform 300ms ease;
  transition: opacity 300ms ease, transform 300ms ease,
    -webkit-transform 300ms ease;
}

.home-2-projects-wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
}

.home-2-latest-works-item {
  position: relative;
  z-index: 5;
  display: inline-block;
  overflow: hidden;
  width: 48%;
  height: 37%;
  border-radius: 10px;
  -webkit-transition: opacity 300ms ease, -webkit-transform 300ms ease;
  transition: opacity 300ms ease, -webkit-transform 300ms ease;
  transition: opacity 300ms ease, transform 300ms ease;
  transition: opacity 300ms ease, transform 300ms ease,
    -webkit-transform 300ms ease;
}

.home-2-latest-works-item:first-child {
  height: 59%;
}

.home-2-latest-works-item:hover {
  opacity: 0.8;
}

.home-2-latest-works-item:active {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.home-2-video-bg-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 937px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.collection-list-wrapper {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 5;
}

.about-projects-wrapper {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 1020px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
}

.works-latest-works-collection {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.works-2-latest-projects-wrapper {
  position: relative;
  z-index: -1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 320px;
  margin-top: 25px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
}

.header-wrapper {
  margin-bottom: 20px;
}

.background-video-404 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: 75vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#000),
    to(transparent)
  );
  background-image: linear-gradient(0deg, #000, transparent);
}

.comimg-soon-wrapper {
  min-height: 100vh;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 0.7)),
    to(rgba(0, 0, 0, 0.7))
  );
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.7)
  );
}

.link-block-blog-post {
  margin-top: 6px;
}

.work-template-video-wrapper {
  overflow: hidden;
  margin-right: 15px;
  margin-bottom: 40px;
  margin-left: 15px;
  border-radius: 10px;
}

.blog-rich-text {
  margin-right: auto;
  text-align: left;
}

.blog-rich-text h2 {
  width: 75%;
  max-width: 700px;
}

.blog-rich-text h3 {
  width: 75%;
  max-width: 700px;
  margin-top: 40px;
  margin-bottom: 25px;
}

.blog-rich-text h4 {
  width: 75%;
  max-width: 700px;
}

.blog-rich-text blockquote {
  width: 75%;
  max-width: 700px;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 0px 0px 0px 30px;
  border-left: 4px solid #1d35a1;
  background-image: none;
  font-family: Poppins, sans-serif;
  font-size: 40px;
  line-height: 1;
  font-weight: 800;
}

.blog-rich-text p {
  width: 75%;
  max-width: 700px;
}

.blog-rich-text figure {
  margin-top: 30px;
  margin-bottom: 40px;
}

.center {
  text-align: center;
}

.landing-banner-title-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 960px;
  margin-bottom: 60px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.text-white {
  color: #fff;
}

.banner-description {
  max-width: 560px;
  margin-top: 20px;
  margin-bottom: 0px;
  font-size: 18px;
  text-align: center;
}

.landing-banner-image {
  max-width: 85%;
  margin-right: auto;
  margin-left: auto;
}

.section-title-description {
  margin-top: 30px;
  text-align: center;
}

.landing-home-pages-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 1480px;
  margin-top: 25px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  grid-auto-columns: 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 50px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.landing-inner-page-item {
  width: 100%;
  padding: 6px;
  border-radius: 10px;
  background-color: #000;
  text-align: center;
}

.landing-inner-page-item.home-screen {
  border-radius: 10px;
}

.landing-inner-page-image-wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
}

.landing-inner-page-image {
  border-radius: 10px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 50% 0%;
  object-position: 50% 0%;
}

.landing-inner-page-icon {
  position: absolute;
  z-index: 10;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50px;
  height: 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  background-color: #0f5cfa;
  font-family: 'Fa solid 900', sans-serif;
  color: #fff;
  font-size: 20px;
}

.landing-inner-page-overlay {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1;
  border-radius: 10px;
  background-color: hsla(0, 0%, 100%, 0.2);
}

.landing-progress-bar-88 {
  position: relative;
  width: 88%;
  height: 15px;
  margin-top: -15px;
  border-radius: 10px;
  background-color: #000;
}

.landing-progress-bar-100 {
  position: relative;
  width: 100%;
  height: 15px;
  margin-top: -15px;
  border-radius: 10px;
  background-color: #000;
}

.landing-progress-bar-95 {
  position: relative;
  width: 95%;
  height: 15px;
  margin-top: -15px;
  border-radius: 10px;
  background-color: #000;
}

.landing-progress-bar-wrapper {
  margin-top: 20px;
}

.landing-progress-bar-gray-line {
  width: 100%;
  height: 15px;
  border-radius: 10px;
  background-color: #eef4f8;
}

.landing-progress-bar-number {
  position: absolute;
  left: auto;
  top: auto;
  right: 0%;
  bottom: 35px;
  font-size: 18px;
  font-weight: 700;
}

.landing-progress-bar-92 {
  position: relative;
  width: 92%;
  height: 15px;
  margin-top: -15px;
  border-radius: 10px;
  background-color: #000;
}

.landing-performance-grid {
  width: 100%;
  margin-top: 25px;
  grid-column-gap: 120px;
  grid-row-gap: 60px;
}

.landing-features-grid {
  margin-top: 25px;
  grid-column-gap: 60px;
  grid-row-gap: 60px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  text-align: center;
}

.landing-feature-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.landing-feature-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50px;
  height: 50px;
  margin-bottom: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  background-color: hsla(0, 0%, 100%, 0.2);
  font-family: 'Fa solid 900', sans-serif;
  color: #fff;
  font-size: 20px;
}

.landing-inner-pages-wrapper {
  display: -ms-grid;
  display: grid;
  max-width: 1480px;
  margin-top: 25px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  grid-auto-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 40px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.landing-download-block-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 1400px;
  margin-right: auto;
  margin-left: auto;
  padding: 75px 80px 75px 200px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: #1d35a1;
  background-image: url('https://assets.website-files.com/636e23ecf118df495e7a2aca/636e23edf118dfedec7a2b1e_Frame%2047.png');
  background-position: 0% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.3);
}

.primary-button-white {
  padding: 16px 22px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #fff;
  background-color: #fff;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #0f5cfa;
  text-align: center;
  letter-spacing: 0.5px;
}

.primary-button2 {
  padding: 10px 22px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-radius: 40px;
  border-color: rgb(255, 255, 255);
  background-color: rgb(0, 0, 0);
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #ffffff;
  text-align: center;
  letter-spacing: 0.5px;
}

.primary-button2:hover {
  background-color: transparent;
  color: rgb(255, 255, 255);
}

.primary-button-white:hover {
  background-color: transparent;
  color: #fff;
}

.download-button-icon {
  margin-top: 4px;
  margin-right: 10px;
  font-family: 'Fa solid 900', sans-serif;
}

.mt-20 {
  margin-top: 20px;
}

.download-header {
  font-size: 48px;
}

.buy-now-webflow-icon {
  margin-right: 8px;
}

.more-templates {
  position: fixed;
  left: auto;
  top: auto;
  right: 12px;
  bottom: 48px;
  z-index: 1000;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 141px;
  padding: 6px;
  border-style: solid;
  border-width: 1px;
  border-color: #e4e4e4;
  border-radius: 4px;
  background-color: #fff;
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
  color: #000;
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
}

.more-templates:hover {
  color: #000;
}

.buy-this-template {
  position: fixed;
  left: auto;
  top: auto;
  right: 12px;
  bottom: 12px;
  z-index: 1000;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 6px;
  border-style: solid;
  border-width: 1px;
  border-color: #e4e4e4;
  border-radius: 4px;
  background-color: #fff;
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
  color: #000;
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
}

.buy-this-template:hover {
  color: #000;
}

@media screen and (min-width: 1280px) {
  h1 {
    font-size: 130px;
  }

  h2 {
    font-size: 90px;
  }

  h3 {
    font-size: 60px;
  }

  .section {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .section.works-details {
    padding-top: 120px;
  }

  .section.top-spacing {
    padding-top: 100px;
  }

  .section.landing-page {
    padding-bottom: 100px;
  }

  .section.color-section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .section.padding-bottom-big {
    padding-bottom: 120px;
  }

  .section.padding-big {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .section.padding-top-big {
    padding-top: 100px;
  }

  .spacing-system-column {
    padding-right: 30px;
  }

  .style-guide-body-wrapper {
    margin-right: 60px;
  }

  .footer {
    padding-top: 100px;
  }

  .footer-rights-wrapper {
    padding-top: 40px;
  }

  .style-guide-buttons-wrapper {
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .footer-wrapper {
    margin-bottom: 100px;
  }

  .without-top-spacing {
    padding-top: 0px;
  }

  .search-section {
    margin-bottom: 80px;
  }

  .project-template-content-wrapper {
    margin-bottom: 50px;
  }

  .testimonials {
    padding-top: 130px;
    padding-bottom: 130px;
  }

  .blog-section-title {
    margin-bottom: 70px;
  }

  .map-wrapper {
    padding-right: 70px;
  }

  .section-title-center {
    margin-bottom: 70px;
  }

  .about-img._3 {
    width: 340px;
  }

  .about-img._4 {
    width: 280px;
  }

  .about-img._2 {
    width: 340px;
  }

  .industries-block-wrapper {
    position: relative;
  }

  .section-with-blue-bg {
    padding-right: 40px;
    padding-left: 40px;
  }

  .center-title {
    padding-right: 140px;
  }

  .extra-bold-text {
    font-size: 140px;
  }

  .extra-bold-text.hover-animation {
    font-size: 140px;
  }
  .events-ani {
    margin: 0;
    padding: 0;
    font-size: 120px;
    text-transform: uppercase;
    position: relative;
    color: #ccc;
  }
  .events-ani :before {
    content: 'events';
    position: absolute;
    color: #8b8b8b;
    top: 0;
    left: 0;
    width: 0%;
    overflow: hidden;
    transition: all 0.5s;
  }
  .events-ani :hover :before {
    width: 100%;
  }
  .proshow-ani {
    margin: 0;
    padding: 0;
    font-size: 120px;
    text-transform: uppercase;
    position: relative;
    color: #ccc;
  }
  .venues-ani {
    margin: 0;
    padding: 0;
    font-size: 120px;
    text-transform: uppercase;
    position: relative;
    color: #ccc;
  }

  .events-ani :hover {
    -webkit-mask-image: linear-gradient(
      -75deg,
      rgba(0, 0, 0, 0.6) 30%,
      #000 50%,
      rgba(0, 0, 0, 0.6) 70%
    );
    -webkit-mask-size: 200%;
    animation: shine 2s infinite;
  }
  .proshow-ani :hover {
    color: #fff;
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 40px #fff, 0 0 80px #fff,
      0 0 120px #fff;
  }
  .venues-ani :hover {
    color: #fff;
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 40px #fff, 0 0 80px #fff,
      0 0 120px #fff;
  }

  .text-and-image-lines-wrapper {
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .about-us-video-wrapper {
    margin-right: 40px;
    margin-left: 40px;
  }

  .image-wrapped-down {
    max-height: 160px;
  }

  .heading-top-banner-title-smaller {
    font-size: 90px;
  }

  .about-us-3-video-wrapper {
    margin-right: 40px;
    margin-left: 40px;
  }

  .about-us-3-awards-content-wrapper {
    grid-row-gap: 50px;
  }

  .contact-us-section {
    padding-top: 190px;
  }

  .contact-us-title {
    font-size: 90px;
  }

  .blog-post-heading {
    font-size: 100px;
  }

  .link-block-blog-post {
    margin-top: 10px;
  }

  .work-template-video-wrapper {
    margin-bottom: 50px;
  }

  .blog-rich-text h3 {
    margin-top: 50px;
    margin-bottom: 30px;
  }

  .blog-rich-text blockquote {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .blog-rich-text figure {
    margin-bottom: 50px;
  }

  .landing-banner-title-wrapper {
    max-width: 1200px;
  }

  .landing-inner-page-item.hiden-4-columns {
    display: none;
  }

  .landing-inner-pages-wrapper {
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .landing-title {
    font-size: 90px;
  }
}

@media screen and (min-width: 1440px) {
  h1 {
    position: relative;
    z-index: 1;
    margin-bottom: 10px;
  }

  h2 {
    margin-bottom: 10px;
  }

  h3 {
    margin-bottom: 10px;
  }

  h4 {
    margin-bottom: 10px;
  }

  h5 {
    margin-bottom: 10px;
  }

  h6 {
    margin-bottom: 10px;
  }

  .section.works-details {
    padding-top: 150px;
  }

  .section.landing-page {
    padding-top: 220px;
    padding-bottom: 100px;
  }

  .base-container.stretch {
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .grid-header {
    margin-top: 50px;
  }

  .nav-container {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .nav-link.footer-link-first {
    padding-right: 25px;
  }

  .paragraph-gray {
    font-size: 26px;
    line-height: 1em;
  }

  .play-button-hover:hover {
    color: #fff;
  }

  .about-img._1 {
    width: 380px;
  }

  .about-img._3 {
    width: 400px;
  }

  .about-img._4 {
    width: 300px;
  }

  .about-img._2 {
    width: 390px;
  }

  .our-services-wrap {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .video-lightbox.home-2:hover {
    color: #fff;
  }

  .section-with-blue-bg {
    padding-right: 100px;
    padding-left: 100px;
  }

  .top-banner-section.about-us {
    padding-top: 220px;
  }

  .background-video.blog-page {
    padding-top: 50px;
  }

  .home-title {
    font-size: 130px;
  }

  .full-width-video {
    min-height: 600px;
  }

  .about-us-video-wrapper {
    margin-right: 60px;
    margin-left: 60px;
  }

  .background-video-about-us {
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(rgba(0, 0, 0, 0.4)),
      to(rgba(0, 0, 0, 0.4))
    );
    background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.4)
    );
  }

  .background-video-about-us.coming-soon {
    background-image: none;
  }

  .about-us-3-video-wrapper {
    margin-right: 60px;
    margin-left: 60px;
  }

  .contact-us-content {
    grid-column-gap: 90px;
  }

  .text-title {
    line-height: 1.5em;
  }

  .work-template-video-wrapper {
    margin-right: 60px;
    margin-left: 60px;
  }

  .landing-title {
    font-size: 130px;
  }

  .download-header {
    font-size: 50px;
  }
}

@media screen and (min-width: 1920px) {
  .nav-link.footer-link-first {
    padding-right: 25px;
  }

  .blog-template-wrapper {
    margin-top: 10px;
  }

  .footer-rights-wrapper {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .footer-rights-wrapper.contact {
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .about-img._1 {
    left: 16%;
    width: 420px;
  }

  .about-img._3 {
    left: 54%;
    top: 20%;
    width: 430px;
  }

  .about-img._4 {
    left: 48%;
  }

  .section-with-blue-bg {
    padding-right: 180px;
    padding-left: 180px;
  }

  .background-video-about-us {
    min-height: 650px;
  }

  .background-video-about-us.about-us-3 {
    min-height: auto;
  }

  .background-video-about-us.blog-page {
    min-height: auto;
  }

  .clients-background-video {
    border-radius: 10px;
  }

  .blog-rich-text h2 {
    margin-bottom: 30px;
  }

  .blog-rich-text h3 {
    margin-top: 50px;
    margin-bottom: 30px;
  }

  .blog-rich-text h4 {
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 991px) {
  h1 {
    font-size: 80px;
  }

  h3 {
    font-size: 36px;
  }

  .grid-system {
    width: 100%;
  }

  .grid-description.first-description {
    text-align: center;
  }

  .spacing-system-image {
    width: 75%;
  }

  .spacing-wrapper-mobile {
    width: 70%;
  }

  .spacing-columns {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .bottom-style-spacing-desktop {
    bottom: 6%;
  }

  .top-style-spacing-desktop {
    top: 4%;
  }

  .middle-style-spacing-desktop {
    top: 30%;
  }

  .spasing-system-image-mobile {
    width: 70%;
  }

  .colors-wrapper {
    margin-right: 40px;
  }

  .colors-container {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .headings-typography-wrapper {
    width: 100%;
    margin-bottom: 30px;
  }

  .headings-typography-wrapper.last-child {
    margin-bottom: 30px;
  }

  .style-guide-body-wrapper {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 30px;
  }

  .top-style-spacing-mobile {
    top: 3%;
  }

  .middlr-style-spacing-mobile {
    top: 26%;
  }

  .bottom-style-spacing-mobile {
    bottom: 6%;
  }

  .navbar {
    background-color: #000;
  }

  .nav-container {
    padding-right: 15px;
    padding-left: 15px;
  }

  .nav-menu {
    left: 0%;
    top: 0%;
    right: auto;
    bottom: 0%;
    width: 320px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: #000;
  }

  .menu-button {
    padding: 0px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    text-align: right;
  }

  .menu-button.w--open {
    background-color: transparent;
  }

  .nav-link {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-right: 0px;
    margin-left: 0px;
    padding: 10px 0px;
    color: #fff;
  }

  .nav-dropdown {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-transition: opacity 500ms ease;
    transition: opacity 500ms ease;
  }

  .nav-dropdown-toggle {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 10px;
    padding-bottom: 0px;
    padding-left: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #fff;
  }

  .nav-dropdown-icon {
    left: auto;
    top: 0%;
    right: 0px;
    bottom: 0%;
    display: block;
    margin-right: 0px;
  }

  .nav-dropdown-list {
    position: static;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 0px 40px 0px 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    border-style: none;
    background-color: #000;
  }

  .nav-dropdown-list.w--open {
    position: static;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 0px;
    padding-bottom: 0px;
    box-shadow: none;
  }

  .nav-dropdown-link-wrapper {
    padding-right: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .nav-dropdown-link-wrapper:hover {
    padding-right: 0px;
    padding-left: 0px;
  }

  .nav-dropdown-link {
    margin-right: 0px;
    margin-left: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #fff;
    font-weight: 500;
  }

  .nav-dropdown-link.w--current {
    color: #fff;
  }

  .close-menu-button {
    padding-bottom: 0px;
  }

  .close-menu-button.w--open {
    padding: 0px 10px 0px 0px;
    background-color: transparent;
  }

  .nav-close-icon {
    z-index: 15;
    max-width: 60%;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }

  .nav-item-title {
    color: #b9b9b9;
    font-weight: 600;
  }

  .style-guide-banner-section {
    min-height: 300px;
    padding-top: 140px;
    padding-bottom: 100px;
  }

  .licensing-paragraph {
    width: 100%;
  }

  .coming-soon-paragraph {
    margin-bottom: 30px;
  }

  .coming-soon-heading {
    font-size: 56px;
    text-align: center;
  }

  .blog-sidebar-post-content {
    padding-bottom: 0px;
  }

  .blog-sidebar-post-category.top-banner-blog-page {
    margin-left: 0px;
  }

  .blog-sidebar-left {
    width: 100%;
    margin-bottom: -30px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .blog-sidebar-right {
    position: static;
    width: 100%;
    height: auto;
  }

  .blog-sidebar-wrapper {
    -webkit-flex-wrap: wrap-reverse;
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
  }

  .sidebar-recent-posts-wrapper {
    -webkit-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 20px;
    column-gap: 20px;
  }

  .sidebar-recent-post-item {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .blog-template-wrapper {
    padding-right: 40px;
    padding-bottom: 40px;
    padding-left: 40px;
  }

  .blog-template-date {
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .blog-template-pagination {
    margin-top: 60px;
    grid-column-gap: 40px;
    grid-row-gap: 40px;
  }

  .footer {
    margin-top: 0px;
  }

  .footer-rights-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    grid-row-gap: 20px;
  }

  .footer-copyright {
    text-align: center;
  }

  .style-guide-button-wrapper {
    width: 100%;
  }

  .footer-wrapper {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    grid-row-gap: 10px;
  }

  .footer-brand-wrapper {
    width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .search-input:focus {
    border-style: solid;
    border-width: 1px;
    border-color: #1d35a1;
  }

  .search-section {
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .headings-container {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .style-guide-content-wrapper {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .paragraph-gray {
    font-size: 20px;
  }

  .project-template-social-link {
    font-size: 20px;
  }

  .nav-dropdown-column {
    width: 180px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .blog-sidebar-right-wrapper {
    width: 100%;
    height: auto;
    margin-bottom: 40px;
  }

  .blog-sidebar-left-wrapper {
    width: 100%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .testimonials {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .blog-section-title {
    margin-bottom: 60px;
  }

  .contacts-wrapper-grid {
    padding-right: 15px;
    padding-left: 15px;
  }

  .section-title-center {
    margin-bottom: 60px;
  }

  .home-4-testimonials-wrapper {
    width: 80%;
    margin-top: 20px;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    grid-column-gap: 60px;
    grid-row-gap: 40px;
  }

  .testimonials-wrapper-2 {
    width: 80%;
    margin-top: 20px;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    grid-column-gap: 60px;
    grid-row-gap: 60px;
  }

  .about-img._1 {
    left: 15px;
    top: 7%;
  }

  .about-img._5 {
    left: 60px;
    top: 58%;
    bottom: 0px;
  }

  .about-img._3 {
    top: 0%;
    right: 15px;
    width: 360px;
  }

  .about-img._4 {
    left: auto;
    top: 57%;
    right: 15px;
    width: 240px;
  }

  .about-img._2 {
    left: 35%;
    top: 30%;
    z-index: 5;
  }

  .subtitle {
    margin-bottom: 30px;
  }

  .home-projects-wrapper {
    height: 1370px;
  }

  .content {
    margin-bottom: 0px;
  }

  .industries-block-wrapper {
    max-width: 45vw;
  }

  .about-4-team-slide {
    width: 100%;
  }

  .nav-dropdown-link-line {
    display: none;
  }

  .subtitle-2 {
    margin-bottom: 30px;
  }

  .dropdown-nav-link {
    padding-top: 12px;
    padding-bottom: 12px;
    font-family: Lato, sans-serif;
    color: #222;
    font-weight: 700;
  }

  .dropdown-nav-link.w--current {
    color: #004ae2;
  }

  .menu-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    background-color: #000;
  }

  .rich-text-style h4 {
    margin-bottom: 10px;
  }

  .home-banner-left-arrow {
    left: 4%;
    top: 20%;
    right: auto;
    bottom: auto;
  }

  .tablet-menu {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 60px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .image-burger {
    margin-bottom: 4px;
    -webkit-filter: invert(100%);
    filter: invert(100%);
  }

  .brand-tablet {
    display: block;
  }

  .navbar-button-wrapper {
    display: none;
  }

  .home-tab-underline {
    display: none;
  }

  .footer-wrapper-top {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .footer-nav-links-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    grid-column-gap: 50px;
  }

  .top-banner-section {
    height: auto;
  }

  .top-banner-section.about-us-2 {
    padding-top: 50px;
  }

  .background-video {
    height: 70vh;
  }

  .text-lines-wrapper {
    grid-row-gap: 20px;
  }

  .text-and-image-lines-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    grid-row-gap: 20px;
  }

  .text-and-image-lines-wrapper.reversed {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .hidden-on-mobile.tablet {
    display: none;
  }

  .about-us-video-wrapper {
    margin-right: 15px;
    margin-left: 15px;
  }

  .image-wrapped-down {
    width: 100%;
  }

  .image-wrapped-down.last-item {
    position: relative;
    max-width: none;
  }

  .image-wrapped-down.first-item {
    position: relative;
    max-width: none;
  }

  .image-wrapped-down.mid-item {
    position: relative;
    max-width: none;
    margin-left: auto;
  }

  .image-wrapped-down.mid-item-copy {
    position: relative;
    margin-left: auto;
  }

  .background-video-colors {
    margin-top: -115px;
  }

  .two-columns-grid {
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .latest-works-video-item.home-2-large {
    width: 100%;
    height: 260px;
  }

  .latest-works-video-item.home-2-smaller {
    width: 100%;
    height: 260px;
  }

  .latest-works-video-item.work-1 {
    height: 260px;
  }

  .home-video-player.home-3 {
    width: auto;
    max-width: none;
  }

  .video-player-image.home-3 {
    height: auto;
  }

  .video-player-image.works-2 {
    width: 100%;
    height: auto;
    min-height: auto;
  }

  .video-info-content-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    grid-row-gap: 40px;
  }

  .video-info-content-wrapper.works-2-top {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .video-info-block-wrapper {
    grid-row-gap: 20px;
  }

  .background-video-visual {
    margin-top: -200px;
  }

  .about-us-3-video-wrapper {
    margin-right: 15px;
    margin-left: 15px;
  }

  .clients-background-video {
    max-height: 330px;
  }

  .extra-bold-text-h3 {
    font-size: 36px;
  }

  .our-clients-content-wrapper-about-us-3 {
    grid-column-gap: 40px;
  }

  .contact-us-section {
    padding-top: 100px;
    padding-bottom: 60px;
  }

  .contact-us-content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    grid-row-gap: 50px;
  }

  .have-an-idea-wrapper {
    max-width: none;
  }

  .contact-form-wrapper {
    max-width: none;
  }

  .contact-us-info {
    max-width: none;
  }

  .contact-us-links-wrapper {
    margin-top: 30px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    grid-column-gap: 40px;
  }

  .radio-buttons-wrapper {
    margin-bottom: 10px;
  }

  .contact-us-fields-wrapper {
    width: 100%;
    margin-bottom: 0px;
  }

  .contact-us-form {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .contact-us-footer {
    padding-bottom: 30px;
  }

  .category-ablsolute-link {
    position: relative;
  }

  .absolte {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    background-color: transparent;
  }

  .nav-link-wrapper {
    padding-left: 0px;
  }

  .process-wrapper {
    grid-row-gap: 40px;
  }

  .e-1-background-videos {
    display: -ms-grid;
    display: grid;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    grid-auto-columns: 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .e-1-background-videos.home-1 {
    -webkit-align-content: space-around;
    -ms-flex-line-pack: distribute;
    align-content: space-around;
  }

  .e-1-background-videos.home-1-copy {
    -webkit-align-content: space-around;
    -ms-flex-line-pack: distribute;
    align-content: space-around;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .background-video-full-width {
    height: 60vh;
  }

  .home-1-latest-works-list {
    display: -ms-grid;
    display: grid;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: end;
    -webkit-align-items: end;
    -ms-flex-align: end;
    align-items: end;
    grid-auto-columns: 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: 260px 260px 260px 260px;
    grid-template-rows: 260px 260px 260px 260px;
  }

  .home-1-latest-works-list.home-1 {
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-grid-rows: 320px 320px 320px 320px;
    grid-template-rows: 320px 320px 320px 320px;
  }

  .home-1-latest-works-list.home-3 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-content: space-around;
    -ms-flex-line-pack: distribute;
    align-content: space-around;
  }

  .home-1-latest-works-item {
    width: 100%;
    height: 100%;
  }

  .home-1-latest-works-item.works-1 {
    max-height: 260px;
  }

  .home-1-latest-works-item.works-1:first-child {
    width: 100%;
  }

  .home-1-latest-works-item.works-1:last-child {
    width: 100%;
  }

  .home-3-latest-works-item {
    width: 100%;
    height: 100%;
  }

  .home-2-projects-wrapper {
    height: 1140px;
  }

  .home-2-latest-works-item {
    width: 100%;
    height: 260px;
  }

  .home-2-video-bg-wrapper {
    height: auto;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .about-projects-wrapper {
    height: 1370px;
  }

  .about-projects-wrapper.worrks {
    height: 1720px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .collection-list-2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .background-video-404 {
    height: 70vh;
  }

  .link-block-blog-post {
    margin-top: 10px;
  }

  .blog-rich-text h4 {
    width: auto;
  }

  .blog-rich-text blockquote {
    width: auto;
  }

  .blog-rich-text p {
    width: auto;
  }

  .landing-banner-image {
    max-width: 95%;
  }

  .landing-home-pages-wrapper {
    justify-items: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .landing-inner-page-item.home-screen {
    max-width: 550px;
    margin-right: auto;
    margin-left: auto;
  }

  .landing-inner-page-item.hiden-4-columns {
    display: none;
  }

  .landing-performance-item-title {
    max-width: 220px;
    font-size: 18px;
  }

  .landing-performance-grid {
    grid-column-gap: 80px;
    grid-row-gap: 60px;
  }

  .landing-features-grid {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .landing-inner-pages-wrapper {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .landing-download-block-wrapper {
    padding: 40px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    background-image: none;
    background-size: auto;
    background-repeat: repeat;
  }

  .landing-download-block-wrap {
    margin-bottom: 40px;
    text-align: center;
  }

  .primary-button-white:hover {
    opacity: 1;
  }

  .landing-title {
    font-size: 70px;
  }

  .download-header {
    font-size: 36px;
  }
}

@media screen and (max-width: 767px) {
  h1 {
    font-size: 48px;
  }

  h2 {
    font-size: 42px;
  }

  blockquote {
    font-size: 18px;
  }

  .section {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .section.works-details {
    padding-top: 100px;
  }

  .section.top-spacing {
    padding-top: 60px;
  }

  .section.landing-page {
    padding-bottom: 60px;
  }

  .section.color-section {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .section.padding-bottom-big {
    padding-bottom: 60px;
  }

  .section.padding-big {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .section.padding-top-big {
    padding-top: 60px;
  }

  .grid-system-wrapper {
    margin-top: 15px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .grid-system {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    border-left: 1px solid #7c7c7c;
  }

  .grid-title {
    width: 100%;
    border-bottom: 1px solid #7c7c7c;
    border-right-style: none;
  }

  .grid-title.first-grid {
    width: 100%;
    border-right-style: none;
    border-left-style: none;
  }

  .grid-description {
    width: 100%;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-top: 1px solid #a7a7a7;
    border-right-width: 1px;
    border-bottom-style: none;
  }

  .grid-description.first-description {
    width: 100%;
    border-width: 1px 1px 0.5px 0px;
    border-top-style: none;
    border-top-color: #a7a7a7;
    border-bottom-style: none;
    border-left-style: none;
  }

  .grid-description.last-description {
    border-right-width: 1px;
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }

  .spacing-system-column {
    margin-top: 20px;
  }

  .spacing-wrapper-mobile {
    margin-top: 30px;
  }

  .typography-wrapper {
    margin-top: 20px;
  }

  .spacing-columns {
    margin-top: 0px;
  }

  .bottom-style-spacing-desktop {
    bottom: 10%;
  }

  .top-style-spacing-desktop {
    top: 10%;
  }

  .spacing-wrapper {
    margin-top: 30px;
  }

  .middle-style-spacing-desktop {
    top: 34%;
  }

  .colors-wrapper {
    margin-top: 15px;
    margin-right: 0px;
  }

  .colors-container {
    margin-top: 0px;
  }

  .headers-wrapper {
    width: 100%;
    margin-top: 10px;
  }

  .h1-tablet {
    font-size: 48px;
  }

  .h2-tablet {
    font-size: 42px;
  }

  .h3-tablet {
    font-size: 36px;
  }

  .style-guide-body-wrapper {
    width: 100%;
    margin-top: 10px;
  }

  .primary-button.full-width-mobile {
    width: 100%;
  }

  .primary-button.search-button {
    width: 100%;
  }

  .style-guide-div {
    margin-top: 35px;
  }

  .top-style-spacing-mobile {
    top: 7%;
  }

  .middlr-style-spacing-mobile {
    top: 31%;
  }

  .bottom-style-spacing-mobile {
    bottom: 9%;
  }

  .grid-header {
    width: 100%;
    margin-top: 35px;
  }

  .link-wrapper {
    width: 100%;
    margin-bottom: 10px;
  }

  .nav-menu {
    width: 100%;
  }

  .nav-link {
    margin-left: 0px;
  }

  .nav-dropdown-icon {
    right: 20px;
  }

  .style-guide-banner-section {
    padding-top: 120px;
    padding-bottom: 60px;
  }

  .licensing-title-wrapper {
    margin-bottom: 20px;
  }

  .licensing-images-wrapper {
    padding-top: 10px;
  }

  .licensing-grid {
    padding-bottom: 40px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .licensing-title {
    padding-bottom: 20px;
  }

  .licensing-fonts-wrapper {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .licensing-paragraph {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 20px;
  }

  .licensing-font-title {
    margin-bottom: 10px;
  }

  .licensing-icons-wrapper {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .password-image {
    width: 100px;
  }

  ._404-paragraph {
    margin-bottom: 30px;
  }

  .text-center-404 {
    max-width: 400px;
    margin-bottom: 0px;
    font-size: 50px;
  }

  .coming-soon-wrapper {
    padding-right: 15px;
    padding-left: 15px;
  }

  .coming-soon-input {
    min-width: 240px;
  }

  .coming-soon-heading {
    font-size: 44px;
  }

  .blog-sidebar-left {
    -webkit-column-count: 1;
    column-count: 1;
  }

  .sidebar-recent-posts-wrapper {
    -webkit-column-count: 1;
    column-count: 1;
  }

  .blog-template-wrapper {
    padding-right: 15px;
    padding-bottom: 20px;
    padding-left: 15px;
  }

  .footer {
    margin-top: 30px;
  }

  .footer-rights-wrapper {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .section-title-wrapper {
    margin-bottom: 40px;
  }

  .style-guide-buttons-wrapper {
    margin-top: 35px;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .style-guide-button-wrapper {
    width: 100%;
  }

  .style-guide-button-wrapper.last-child {
    margin-bottom: 0px;
  }

  .mb-30.centered {
    margin-top: 10px;
  }

  .footer-wrapper {
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .footer-brand-wrapper {
    margin-bottom: 20px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .search-result-item {
    margin-bottom: 20px;
  }

  .about-3-tab-link.w--current {
    font-size: 20px;
  }

  .project-template-info {
    width: 100%;
    margin-top: 0px;
  }

  .project-template-content-wrapper {
    margin-bottom: 30px;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .project-template-info-wrap {
    padding: 10px;
  }

  .project-template-social-link {
    margin-right: 15px;
  }

  .blog-sidebar-left-wrapper {
    -webkit-column-count: 1;
    column-count: 1;
  }

  .testimonials {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .blog-section-title {
    margin-bottom: 40px;
  }

  .contacts-wrapper-grid {
    grid-column-gap: 20px;
    grid-row-gap: 40px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .section-title-center {
    margin-bottom: 40px;
  }

  .home-4-testimonials-wrapper {
    width: 100%;
    grid-row-gap: 30px;
  }

  .testimonials-wrapper-2 {
    width: 100%;
  }

  .about-img {
    width: 100%;
    max-height: 420px;
  }

  .about-img._1 {
    position: static;
    width: 100%;
  }

  .about-img._5 {
    position: static;
    width: 100%;
  }

  .about-img._3 {
    position: static;
    width: 100%;
  }

  .about-img._4 {
    position: static;
    width: 100%;
  }

  .about-img._2 {
    position: static;
    width: 100%;
  }

  .subtitle {
    line-height: 16px;
  }

  .our-services-wrap {
    width: 100%;
    grid-row-gap: 10px;
  }

  .content-wrapper {
    width: auto;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .projects-name {
    font-size: 22px;
  }

  .home-projects-wrapper {
    height: 1100px;
  }

  .video-lightbox {
    left: 0%;
    right: 0%;
    bottom: 40%;
    margin-right: auto;
    margin-left: auto;
  }

  .video-lightbox.absolute {
    bottom: auto;
  }

  .video-lightbox.home-2 {
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .industries-block-wrapper {
    max-width: none;
  }

  .home-9-team-item {
    width: 48%;
  }

  .subtitle-2 {
    line-height: 16px;
  }

  .home-banner-left-arrow {
    top: 6%;
  }

  .home-23-team-item {
    width: 70%;
  }

  .brand-tablet {
    padding-left: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .brand {
    padding-left: 0px;
  }

  .footer-wrapper-top {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }

  .loop-section {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .top-banner-section.about-us {
    padding-bottom: 50px;
  }

  .top-banner-section.blog-page {
    height: 50vh;
  }

  .top-banner-section.blog-category-page {
    max-height: 400px;
  }

  .background-video {
    height: 60vh;
  }

  .background-video.blog-page {
    height: 50vh;
    padding-top: 70px;
  }

  .home-title {
    font-size: 60px;
  }

  .extra-bold-text {
    font-size: 60px;
  }

  .text-and-image-lines-wrapper {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .three-columns-grid {
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .our-clients-names-wrapper {
    grid-row-gap: 30px;
  }

  .our-clients-names-wrapper.left-align {
    grid-row-gap: 20px;
  }

  .our-clients-names-wrapper.left-align-about-3 {
    grid-row-gap: 20px;
  }

  .hidden-on-mobile {
    display: none;
  }

  .about-us-video-wrapper {
    margin-top: 20px;
  }

  .image-wrapped-down {
    margin-right: auto;
  }

  .background-video-colors {
    margin-top: -60px;
  }

  .background-video-about-us.blog-page {
    max-height: 250px;
  }

  .latest-works-video-item {
    height: 260px;
  }

  .latest-works-video-item.home-2-large {
    width: 100%;
    height: 260px;
  }

  .latest-works-video-item.home-2-smaller {
    width: 100%;
    height: 260px;
  }

  .video-title {
    left: auto;
    bottom: 30px;
    width: 100%;
  }

  .home-video-player {
    overflow: hidden;
    width: 100%;
    height: 275px;
    text-align: center;
  }

  .video-player-image {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .video-info-content-wrapper {
    grid-row-gap: 30px;
  }

  .heading-top-banner-title-smaller {
    font-size: 52px;
  }

  .our-clients-link-image.smaller {
    width: 35px;
  }

  .background-video-visual {
    margin-top: -145px;
  }

  .absolute-top-banner-title {
    text-align: center;
  }

  .about-us-background-video-3 {
    padding-right: 30px;
    padding-left: 30px;
    grid-row-gap: 20px;
  }

  .clients-background-video {
    max-height: 220px;
  }

  .extra-bold-text-h3 {
    font-size: 32px;
  }

  .our-clients-content-wrapper-about-us-3 {
    grid-column-gap: 30px;
  }

  .max-width-70 {
    width: 80%;
  }

  .contact-us-section {
    padding-bottom: 40px;
  }

  .contact-us-content {
    grid-row-gap: 30px;
  }

  .have-an-idea-wrapper {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }

  .contact-form-wrapper {
    text-align: center;
  }

  .contact-us-title {
    font-size: 54px;
  }

  .contact-us-links-wrapper {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    grid-column-gap: 10px;
  }

  .contact-us-links-wrapper.mobile {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-top: 60px;
  }

  .contact-us-links-wrapper.desctop {
    display: none;
  }

  .radio-buttons-wrapper {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    grid-row-gap: 20px;
  }

  .contact-us-form {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .process-wrapper {
    grid-row-gap: 30px;
  }

  .e-1-background-videos {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    grid-row-gap: 20px;
  }

  .home-1-latest-works-list {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    grid-row-gap: 20px;
  }

  .home-1-latest-works-list.home-1 {
    -ms-grid-rows: 260px 260px 260px 260px;
    grid-template-rows: 260px 260px 260px 260px;
  }

  .home-1-latest-works-list.home-2 {
    -webkit-align-content: space-around;
    -ms-flex-line-pack: distribute;
    align-content: space-around;
  }

  .home-1-latest-works-list.works-1 {
    grid-row-gap: 35px;
  }

  .home-1-latest-works-item {
    width: 100%;
  }

  .home-3-latest-projects-wrapper {
    height: 1120px;
    margin-top: 15px;
  }

  .home-3-latest-works-item {
    width: 100%;
  }

  .home-2-latest-works-item {
    width: 100%;
    height: 100%;
  }

  .home-2-latest-works-item:first-child {
    height: 100%;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .about-projects-wrapper {
    height: 1100px;
  }

  .works-2-latest-projects-wrapper {
    height: 260px;
    margin-top: 15px;
  }

  .background-video-404 {
    height: 60vh;
  }

  .work-template-video-wrapper {
    margin-bottom: 30px;
  }

  .blog-rich-text {
    width: auto;
  }

  .blog-rich-text h3 {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .blog-rich-text h4 {
    max-width: none;
  }

  .blog-rich-text blockquote {
    max-width: none;
    margin-top: 30px;
    margin-bottom: 30px;
    padding-left: 20px;
  }

  .blog-rich-text p {
    max-width: none;
  }

  .blog-rich-text figure {
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .landing-home-pages-wrapper {
    margin-top: 15px;
    grid-row-gap: 40px;
  }

  .landing-inner-page-item.hiden-4-columns {
    display: block;
  }

  .landing-performance-grid {
    margin-top: 15px;
    grid-column-gap: 80px;
    grid-row-gap: 40px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .landing-features-grid {
    margin-top: 15px;
    grid-column-gap: 40px;
    grid-row-gap: 40px;
  }

  .landing-inner-pages-wrapper {
    margin-top: 15px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .landing-download-block-wrap {
    margin-bottom: 30px;
  }

  .landing-title {
    font-size: 52px;
  }
}

@media screen and (max-width: 479px) {
  .section.works-details {
    padding-top: 80px;
  }

  .grid-system-wrapper {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .grid-system {
    width: 50%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .grid-title {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .grid-title.first-grid {
    width: 100%;
    text-align: center;
  }

  .grid-description {
    border-bottom-width: 1px;
    border-bottom-color: hsla(0, 0%, 100%, 0.2);
  }

  .grid-description.first-description {
    padding-right: 0px;
    padding-left: 0px;
    border-bottom: 1px none hsla(0, 0%, 100%, 0.2);
    border-right-style: solid;
    border-left-style: none;
  }

  .grid-description.last-description {
    border-bottom-color: #a7a7a7;
  }

  .bottom-style-spacing-desktop {
    bottom: 8%;
  }

  .top-style-spacing-desktop {
    top: 7%;
  }

  .middle-style-spacing-desktop {
    top: 32%;
  }

  .color-container {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .primary-button.full-width-mobile {
    width: 100%;
  }

  .top-style-spacing-mobile {
    top: 5%;
  }

  .middlr-style-spacing-mobile {
    top: 28%;
  }

  .bottom-style-spacing-mobile {
    bottom: 8%;
  }

  .nav-link {
    width: 99%;
  }

  .nav-link.footer-link {
    width: 35%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }

  .nav-link.footer-link-first {
    width: 35%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }

  .licensing-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .license-link {
    margin-right: 15px;
  }

  .text-center-404 {
    font-size: 40px;
  }

  ._404-content-wrapper {
    padding-right: 15px;
    padding-left: 15px;
  }

  .coming-soon-form {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .coming-soon-input {
    min-width: auto;
    margin-bottom: 10px;
  }

  .coming-soon-paragraph {
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: center;
  }

  .coming-soon-heading {
    font-size: 32px;
  }

  .blog-sidebar-post-category {
    margin-bottom: 20px;
  }

  .blog-template-pagination {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }

  .blog-template-prev-button {
    width: 100%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .blog-template-next-button {
    width: 100%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .footer {
    text-align: left;
  }

  .footer-rights-wrapper {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .footer-copyright {
    width: 100%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }

  .section-title-wrapper {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    grid-row-gap: 20px;
  }

  .footer-wrapper {
    margin-bottom: 40px;
  }

  .footer-brand-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .about-3-tab-link {
    font-size: 16px;
  }

  .about-3-tab-link.w--current {
    font-size: 18px;
  }

  .project-template-info-wrap {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }

  .section-title-center {
    margin-bottom: 20px;
  }

  .our-services-wrap {
    width: 100%;
  }

  .projects-name {
    max-width: 220px;
  }

  .home-9-team-item {
    width: 100%;
  }

  .home-23-team-item {
    width: 100%;
  }

  .center-title {
    text-align: center;
  }

  .brand-tablet {
    padding-left: 0px;
  }

  .footer-nav-links-wrapper {
    width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    justify-items: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    grid-auto-columns: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .extra-bold-text {
    font-size: 50px;
  }

  .our-clients-link-image {
    width: 30px;
  }

  .our-clients-link-image.smaller {
    margin-right: -5px;
  }

  .our-clients-link-image.smaller:hover {
    margin-right: -10px;
  }

  .our-clients-content-wrapper-about-us-3 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    grid-row-gap: 30px;
  }

  .our-client-image-about-us-3-wrapper {
    grid-row-gap: 20px;
  }

  .about-us-3-awards-content-wrapper {
    grid-row-gap: 20px;
  }

  .max-width-70 {
    width: auto;
  }

  .contact-us-links-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .link-block-blog-post {
    margin-top: 0px;
  }

  .landing-features-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .landing-feature-icon {
    margin-bottom: 10px;
  }
}

#w-node-ae20afb3-e4c1-57e3-e348-d425671db2d8-d17a2ae0 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-dd40a705-ecf2-aac4-13ce-00e9a118a940-d17a2ae0 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_4620e830-82a3-04a7-e90f-0591290dafa5-d17a2ae0 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_1f91660b-80ea-6a6e-868b-3718c1de8669-ed7a2ae6 {
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-f5244ba1-8aa0-622e-1717-4303447ae26d-ed7a2ae6 {
  -ms-grid-row: span 2;
  grid-row-start: span 2;
  -ms-grid-row-span: 2;
  grid-row-end: span 2;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_1f91660b-80ea-6a6e-868b-3718c1de8673-ed7a2ae6 {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-_90c8d65d-5b59-8209-46c6-d0730519e6dc-ed7a2ae6 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_90c8d65d-5b59-8209-46c6-d0730519e6de-ed7a2ae6 {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_90c8d65d-5b59-8209-46c6-d0730519e6df-ed7a2ae6 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_90c8d65d-5b59-8209-46c6-d0730519e6e1-ed7a2ae6 {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_90c8d65d-5b59-8209-46c6-d0730519e6e7-ed7a2ae6 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_90c8d65d-5b59-8209-46c6-d0730519e6e8-ed7a2ae6 {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_90c8d65d-5b59-8209-46c6-d0730519e6e9-ed7a2ae6 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_90c8d65d-5b59-8209-46c6-d0730519e6eb-ed7a2ae6 {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_82acc24e-2ad8-8820-3fb7-0cd1c36116e2-a77a2ae7 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_2a96eddd-621f-f159-320e-360f9dfeefdd-a77a2ae7 {
  -ms-grid-row: span 2;
  grid-row-start: span 2;
  -ms-grid-row-span: 2;
  grid-row-end: span 2;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_5f88e9ff-4f3f-40e6-6490-db151273e42f-a77a2ae7 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_5f88e9ff-4f3f-40e6-6490-db151273e431-a77a2ae7 {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_5f88e9ff-4f3f-40e6-6490-db151273e432-a77a2ae7 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_5f88e9ff-4f3f-40e6-6490-db151273e434-a77a2ae7 {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_5f88e9ff-4f3f-40e6-6490-db151273e43b-a77a2ae7 {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_5f88e9ff-4f3f-40e6-6490-db151273e43c-a77a2ae7 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_5f88e9ff-4f3f-40e6-6490-db151273e43e-a77a2ae7 {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-b0109ceb-8a41-87bf-b696-b0c54442640e-fd7a2b0d {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-b0109ceb-8a41-87bf-b696-b0c544426410-fd7a2b0d {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-b0109ceb-8a41-87bf-b696-b0c544426411-fd7a2b0d {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-b0109ceb-8a41-87bf-b696-b0c544426413-fd7a2b0d {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-b0109ceb-8a41-87bf-b696-b0c544426419-fd7a2b0d {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-b0109ceb-8a41-87bf-b696-b0c54442641a-fd7a2b0d {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-b0109ceb-8a41-87bf-b696-b0c54442641b-fd7a2b0d {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-b0109ceb-8a41-87bf-b696-b0c54442641d-fd7a2b0d {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-b6c938bb-7e15-85c8-61a3-2b0649baa2e7-857a2b65 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_221756cf-87a5-f33b-cb38-d85adbcf20cb-857a2b65 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 3;
  grid-row-start: span 3;
  -ms-grid-row-span: 3;
  grid-row-end: span 3;
}

#w-node-_6c4504af-3da1-e5b6-94a1-86dac479ed7f-857a2b65 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_9ef195c2-244e-fc3b-b7b8-3f7abe48441a-857a2b65 {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-ce48ef5c-d723-6ebb-5872-16f45cbe1307-857a2b65 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-ce48ef5c-d723-6ebb-5872-16f45cbe1309-857a2b65 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-ce48ef5c-d723-6ebb-5872-16f45cbe130a-857a2b65 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_1b976fab-d26a-f988-6543-2ac6c07f86ab-027a2b76 {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-d5a5db5b-7f92-f3b3-fcba-da5a6651ddb9-027a2b76 {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-dac90751-a7a0-32b3-aa26-365a9edebcb9-027a2b76 {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_5ec15608-86d2-a9a9-3aa3-bede457fbc47-027a2b76 {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_5710c4ea-4798-2e1d-ca06-be1738e0f85a-027a2b76 {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_6f5b7b17-ef88-90d0-5b66-47208b1bf332-027a2b76 {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_50232c47-4a45-093a-e8ed-7654016b90b4-027a2b76 {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-e72818c0-c2c2-46ed-5b93-f795a0c0ab7b-027a2b76 {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_7faf4b20-1801-dfe8-3e05-3a39389f4106-027a2b76 {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-e08d34e8-650c-66a3-c1eb-f82900d47eff-027a2b76 {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_890eb5de-0313-e3b8-52de-8662b39ad244-027a2b76 {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_6e5757a4-e41e-982c-e805-e9403e7b6231-027a2b76 {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_4bbb709f-08af-29bf-d9ec-4025da843192-027a2b76 {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_221d04f8-8434-c849-a680-6ecd27336eff-027a2b76 {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_5e642648-f007-725d-69c9-7d55d652efdd-027a2b76 {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_6a078d37-a03b-58b0-5746-bd390040e948-e77a2b84 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_6a078d37-a03b-58b0-5746-bd390040e94a-e77a2b84 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_6a078d37-a03b-58b0-5746-bd390040e94c-e77a2b84 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_6a078d37-a03b-58b0-5746-bd390040e94e-e77a2b84 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_6a078d37-a03b-58b0-5746-bd390040e950-e77a2b84 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_6a078d37-a03b-58b0-5746-bd390040e953-e77a2b84 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_6a078d37-a03b-58b0-5746-bd390040e955-e77a2b84 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_6a078d37-a03b-58b0-5746-bd390040e957-e77a2b84 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_6a078d37-a03b-58b0-5746-bd390040e959-e77a2b84 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_6a078d37-a03b-58b0-5746-bd390040e95b-e77a2b84 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-d422ae9c-9ef0-6fa9-562c-e281f5baee84-69d6288c {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-a22426a9-98ef-17b2-5bb1-3581c16fa98a-69d6288c {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-ce5c0849-bb14-ba12-7c3d-9b412fb812f3-69d6288c {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_7a45e0d8-91b1-4888-a445-05bc9984f30d-69d6288c {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-a611add8-b767-ccc8-8a85-a18bad018403-69d6288c {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_0cafc351-d903-dc48-661f-3cf82c6b25d1-69d6288c {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_67b66581-ac56-9c04-363a-0fdd1ce38c2d-69d6288c {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-_7c8c374e-d0a4-f2d8-56f4-d5ba08047928-69d6288c {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_818e1ac2-f38d-69ab-3236-be4b765b9c5a-69d6288c {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_818e1ac2-f38d-69ab-3236-be4b765b9c60-69d6288c {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-ccd90bb3-c2a2-3fea-9af6-07b6ed15d2b3-69d6288c {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_818e1ac2-f38d-69ab-3236-be4b765b9c5c-69d6288c {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-b14ffe70-6acc-705b-294e-f0dd743916f8-69d6288c {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_420d22ab-ab09-c433-fa2f-5ac5c7dbedb0-69d6288c {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_420d22ab-ab09-c433-fa2f-5ac5c7dbedb1-69d6288c {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_420d22ab-ab09-c433-fa2f-5ac5c7dbedb3-69d6288c {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-cb2bed27-a200-0603-8d53-2dc442e557d8-05ad19d8 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-cb2bed27-a200-0603-8d53-2dc442e557d9-05ad19d8 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-cb2bed27-a200-0603-8d53-2dc442e557da-05ad19d8 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-cb2bed27-a200-0603-8d53-2dc442e557db-05ad19d8 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-f7dab42d-3c51-1923-99ae-5cb7650977df-607bd46b {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-f7dab42d-3c51-1923-99ae-5cb7650977e1-607bd46b {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-f7dab42d-3c51-1923-99ae-5cb7650977e2-607bd46b {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-ad4d6a52-c19f-65e4-93dd-cc6050c43674-607bd46b {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_0428652d-2a35-2226-b1b4-d8d4fb8d018e-607bd46b {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_0310841f-4c84-cf11-19f1-c41fa015b944-3b8e14d3 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_0310841f-4c84-cf11-19f1-c41fa015b946-3b8e14d3 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_0310841f-4c84-cf11-19f1-c41fa015b947-3b8e14d3 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_4f530a55-6b15-8341-400f-5581745dfa32-3b8e14d3 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-f4179f9c-3032-38d6-5ca1-2833fdf2ff09-ce3c7c0d {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-f4179f9c-3032-38d6-5ca1-2833fdf2ff0b-ce3c7c0d {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-f4179f9c-3032-38d6-5ca1-2833fdf2ff0c-ce3c7c0d {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-f4179f9c-3032-38d6-5ca1-2833fdf2ff1a-ce3c7c0d {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-fbac8b85-0bff-10f7-57a5-cf4d54093e9c-0e29594a {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-fbac8b85-0bff-10f7-57a5-cf4d54093ea4-0e29594a {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-fbac8b85-0bff-10f7-57a5-cf4d54093eac-0e29594a {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-fbac8b85-0bff-10f7-57a5-cf4d54093eb4-0e29594a {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

@media screen and (max-width: 991px) {
  #w-node-ae20afb3-e4c1-57e3-e348-d425671db2d8-d17a2ae0 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_4620e830-82a3-04a7-e90f-0591290dafa5-d17a2ae0 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-b6c938bb-7e15-85c8-61a3-2b0649baa2e7-857a2b65 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_221756cf-87a5-f33b-cb38-d85adbcf20cb-857a2b65 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 2;
    grid-row-start: span 2;
    -ms-grid-row-span: 2;
    grid-row-end: span 2;
  }

  #w-node-_6c4504af-3da1-e5b6-94a1-86dac479ed7f-857a2b65 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-ce48ef5c-d723-6ebb-5872-16f45cbe1307-857a2b65 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-ce48ef5c-d723-6ebb-5872-16f45cbe130a-857a2b65 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-ce48ef5c-d723-6ebb-5872-16f45cbe130d-857a2b65 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-cb2bed27-a200-0603-8d53-2dc442e557d8-05ad19d8 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-cb2bed27-a200-0603-8d53-2dc442e557d9-05ad19d8 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-cb2bed27-a200-0603-8d53-2dc442e557da-05ad19d8 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-cb2bed27-a200-0603-8d53-2dc442e557db-05ad19d8 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-f7dab42d-3c51-1923-99ae-5cb7650977df-607bd46b {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-f7dab42d-3c51-1923-99ae-5cb7650977e2-607bd46b {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-ad4d6a52-c19f-65e4-93dd-cc6050c43674-607bd46b {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_0428652d-2a35-2226-b1b4-d8d4fb8d018e-607bd46b {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-f7dab42d-3c51-1923-99ae-5cb7650977e5-607bd46b {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_0310841f-4c84-cf11-19f1-c41fa015b944-3b8e14d3 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_0310841f-4c84-cf11-19f1-c41fa015b947-3b8e14d3 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_0310841f-4c84-cf11-19f1-c41fa015b94a-3b8e14d3 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_4f530a55-6b15-8341-400f-5581745dfa32-3b8e14d3 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-f4179f9c-3032-38d6-5ca1-2833fdf2ff09-ce3c7c0d {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-f4179f9c-3032-38d6-5ca1-2833fdf2ff0c-ce3c7c0d {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-f4179f9c-3032-38d6-5ca1-2833fdf2ff0f-ce3c7c0d {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-f4179f9c-3032-38d6-5ca1-2833fdf2ff1a-ce3c7c0d {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }
}

@media screen and (max-width: 767px) {
  #w-node-ae20afb3-e4c1-57e3-e348-d425671db2d8-d17a2ae0 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_1f91660b-80ea-6a6e-868b-3718c1de8669-ed7a2ae6 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_1f91660b-80ea-6a6e-868b-3718c1de866e-ed7a2ae6 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_6c4504af-3da1-e5b6-94a1-86dac479ed7f-857a2b65 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-ce48ef5c-d723-6ebb-5872-16f45cbe1307-857a2b65 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-d422ae9c-9ef0-6fa9-562c-e281f5baee84-69d6288c {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-a22426a9-98ef-17b2-5bb1-3581c16fa98a-69d6288c {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-cb2bed27-a200-0603-8d53-2dc442e557d8-05ad19d8 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-f7dab42d-3c51-1923-99ae-5cb7650977df-607bd46b {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_0310841f-4c84-cf11-19f1-c41fa015b944-3b8e14d3 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_4f530a55-6b15-8341-400f-5581745dfa32-3b8e14d3 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-f4179f9c-3032-38d6-5ca1-2833fdf2ff09-ce3c7c0d {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-f4179f9c-3032-38d6-5ca1-2833fdf2ff1a-ce3c7c0d {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }
}

@media screen and (max-width: 479px) {
  #w-node-_1b976fab-d26a-f988-6543-2ac6c07f86ab-027a2b76 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-d5a5db5b-7f92-f3b3-fcba-da5a6651ddb9-027a2b76 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-dac90751-a7a0-32b3-aa26-365a9edebcb9-027a2b76 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_5ec15608-86d2-a9a9-3aa3-bede457fbc47-027a2b76 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_5710c4ea-4798-2e1d-ca06-be1738e0f85a-027a2b76 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_6f5b7b17-ef88-90d0-5b66-47208b1bf332-027a2b76 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_50232c47-4a45-093a-e8ed-7654016b90b4-027a2b76 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-e72818c0-c2c2-46ed-5b93-f795a0c0ab7b-027a2b76 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_7faf4b20-1801-dfe8-3e05-3a39389f4106-027a2b76 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-e08d34e8-650c-66a3-c1eb-f82900d47eff-027a2b76 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_890eb5de-0313-e3b8-52de-8662b39ad244-027a2b76 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_6e5757a4-e41e-982c-e805-e9403e7b6231-027a2b76 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_4bbb709f-08af-29bf-d9ec-4025da843192-027a2b76 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_221d04f8-8434-c849-a680-6ecd27336eff-027a2b76 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_5e642648-f007-725d-69c9-7d55d652efdd-027a2b76 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_7a45e0d8-91b1-4888-a445-05bc9984f30d-69d6288c {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-a611add8-b767-ccc8-8a85-a18bad018403-69d6288c {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }
}
@font-face {
  font-family: 'Fa brands 400';
  src: url('https://assets.website-files.com/636e23ecf118df495e7a2aca/636e23edf118df5e647a2b02_fa-brands-400.ttf')
    format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Fa solid 900';
  src: url('https://assets.website-files.com/636e23ecf118df495e7a2aca/636e23edf118dffda57a2b03_fa-solid-900.ttf')
    format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Fa 400';
  src: url('https://assets.website-files.com/636e23ecf118df495e7a2aca/636e23edf118df58117a2b12_fa-regular-400.ttf')
    format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
.w-webflow-badge {
  display: none !important;
}
