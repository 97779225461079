.grid-container {
  max-width: 1800px;
  margin: 0 auto;
  display: grid;
  gap: 1rem;
}

.grid-item {
  display: flex;
  justify-content: space-evenly;
  /* flex-direction: column; */
  align-content: space-around;
  flex-wrap: wrap;
  padding: 20px;
  font-size: 30px;
  text-align: center;
}

@media (min-width: 600px) {
  .nav-menu-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 900px) {
  .grid-container {
    grid-template-columns: repeat(4, 1fr);
  }
}

.mention-card:before {
  border-radius: 20px 20px 20px 20px;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    180deg,
    hsla(0, 0%, 35.29%, 0) 0%,
    hsla(0, 0%, 34.53%, 0.034375) 16.36%,
    hsla(0, 0%, 32.42%, 0.125) 33.34%,
    hsla(0, 0%, 29.18%, 0.253125) 50.1%,
    hsla(0, 0%, 24.96%, 0.4) 65.75%,
    hsla(0, 0%, 19.85%, 0.546875) 79.43%,
    hsla(0, 0%, 13.95%, 0.675) 90.28%,
    hsla(0, 0%, 7.32%, 0.765625) 97.43%,
    hsla(0, 0%, 0%, 0.8) 100%
  );
  z-index: 1;
}

.mention-card {
  height: 300px;
  width: 200px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: start;
  transition: 0.2s all ease-in;
}

.mention-card h6 {
  color: #ffffff;
  text-align: start;
  margin-bottom: 30px;
  margin-left: 10px;
  z-index: 1;
}

.mention-card img {
  border-radius: 20px 20px 20px 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mention-card:hover {
  transform: scale(1.02);
}

.promo-container {
  background-image: url('./assets/images/gradient-img.jpg');
  height: 250px;
  width: 85%;
  display: flex;
  overflow: hidden;
  border-radius: 25px;
  background-color: #ffffff;
  background-size: cover;
  background-position: center;
  justify-content: space-between;
  flex-direction: row;
  align-content: stretch;
  align-items: center;
}
.promo-container-wrapper {
  display: flex;
  justify-content: center;
}

.promo-subtitle {
  color: #ffffffbd;
  font-weight: 300;
}
.promo-title {
  font-size: 40px;
}
.app-store-badge {
  height: 50px;
}
.store-logos {
  display: flex;
}
.promo-container-text {
  padding: 30px;
}
.promo-links {
  padding-left: 150px;
}
.app-screenshot {
  height: 431px;
  position: relative;
  right: 35px;
  top: 43px;
}

@media only screen and (max-width: 600px) {
  .app-screenshot {
    display: none;
  }
}

.promo-container-wrapper {
  padding-top: 40px;
  padding-bottom: 40px;
}
